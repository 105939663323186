import { Dropdown, Form, Image, Placeholder } from "react-bootstrap";
import { ButtonIcon, HeaderStyled, SelectTeam } from "./styled";
import { BiChevronDown, BiChevronLeft, BiChevronRight, BiUser } from "react-icons/bi";
import Link from "next/link";
import { AiOutlineLogout } from "react-icons/ai";
import { useAuthProv } from "@/hooks/AuthHook";
import DropdownServices from "@/components/DropdownServices";
import { useMain } from "@/hooks/main";
import React, { ChangeEvent } from "react";
import { FaCog, FaUser } from "react-icons/fa";
import { setCookie } from "@/utils/cookies";
import { encodeAES } from "@/utils/helpers";
import NoImgPhoto from "@/assets/no-img.svg"

interface Props {
    setShowMenu: (showMenu: boolean) => void;
    hideMenu: boolean;
    setHideMenu: (hideMenu: boolean) => void;
    isInvited: boolean,
    hasTeam: boolean | undefined
}
export default function Header({setShowMenu, hideMenu, setHideMenu, isInvited, hasTeam}: Props) {
    const {logout} = useAuthProv()
    const {infoUser, handleGetPermissionsModules, changedRouter, setChangedRouter } = useMain()

    const handleTeam = React.useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        const idTeam = Number(e.target.value);
        setCookie('idTeam', encodeAES(idTeam.toString()))
        setTimeout(()=>{
            handleGetPermissionsModules(idTeam);
        },250)
    },[]);

    return (
        <HeaderStyled data-cy='header' id='header-content' as='div' $initialContent={isInvited || !hasTeam}>
            {
                !isInvited && hasTeam && <>
                    <ButtonIcon data-cy='button-hide-menu' as='button' className={hideMenu ? "button-hide-menu-names hide-menu" : 'button-hide-menu-names'} onClick={() => {setHideMenu(!hideMenu)}}>
                        <BiChevronLeft size={28}/>
                    </ButtonIcon>
                    <ButtonIcon data-cy='button-open-menu-mobile' as='button' className="button-show-menu-mobile" onClick={() => {
                      setShowMenu(true)
                      setTimeout(() => {
                        const {pathname} = window.location
                        const menuItensLink = document.querySelectorAll('.menu-item a')
                        menuItensLink.forEach((link : any) => {
                          if(link.getAttribute('href') === pathname) {
                            if(!link.classList.contains('active')) link.classList.add('active')
                          } else {
                            link.classList.remove('active')
                          }
                        })
                      }, 250)
                    }}>
                        <BiChevronRight size={22}/>
                    </ButtonIcon>
                </>
            }
            <div className="user-actions">
                {/* {
                    !isInvited && hasTeam &&
                        <DropdownAlerts/>
                } */}
                <DropdownServices/>
                {infoUser.has_team &&
                    <SelectTeam>
                        <div>
                            <Form.Select id='select_team' onChange={(e) => handleTeam(e)} aria-label='Select para troca de equipe' value={infoUser.teamSelected?.id}>
                                {infoUser.teams?.map((team, key) => (
                                    <option
                                        key={key + '-' + team.id}
                                        value={team.id}
                                    >
                                        {team.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </SelectTeam>
                }
                <Dropdown className='dropdown-user'>
                    <Dropdown.Toggle id='dropdown-user'>
                        <div className="circle">
                            {
                                Object.keys(infoUser).length === 0
                                ? <BiUser size={22}/>
                                : infoUser.user.logo === null
                                    ? <BiUser size={22}/>
                                    : <Image alt={infoUser.user.first_name + ' ' + infoUser.user.last_name} src={infoUser.user.logo} width={100} height={100} onError={
                                      (e) => {
                                        e.currentTarget.src = NoImgPhoto.src
                                        e.currentTarget.classList.add('img-object-fit-contain')
                                    }}/>
                            }
                        </div>
                        <div className="infos">
                            {
                                Object.keys(infoUser).length === 0
                                ? <>
                                    <Placeholder as='p' animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                    <Placeholder as='p' animation="glow">
                                        <Placeholder xs={12} />
                                    </Placeholder>
                                </>
                                : <>
                                    <p className="name">{infoUser.user?.first_name} {infoUser.user?.last_name}</p>
                                    <p className="role">{infoUser.teamSelected?.role}</p>
                                </>
                            }
                        </div>
                        <BiChevronDown size={22}/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <div className="infos-mobile">
                            <p className="name">{infoUser.user?.first_name} {infoUser.user?.last_name}</p>
                            <p className="role">{infoUser.teamSelected?.role ? infoUser.teamSelected?.role : "Função"}</p>
                        </div>
                        <Dropdown.Divider className="dropdown-divider-mobile"/>
                        {
                            Object.keys(infoUser).length !== 0 &&
                                <Link 
                                    href='/perfil'
                                    id='dropdown-perfil' 
                                    className="d-flex gap-1 dropdown-item" 
                                    onClick={()=>{
                                        setTimeout(()=>{
                                            setChangedRouter(!changedRouter)
                                        },250) 
                                    }}
                                >
                                    <FaUser size='1rem'/> Perfil
                                </Link>
                        }
                        {
                            Object.keys(infoUser).length !== 0 && infoUser.teamSelected?.is_admin &&
                            <>
                                <Link 
                                    href='/configuracoes'
                                    id='dropdown-config' 
                                    className="d-flex gap-1 dropdown-item" 
                                    onClick={()=>{
                                        setTimeout(()=>{
                                            setChangedRouter(!changedRouter)
                                        },250) 
                                    }}
                                >
                                    <FaCog size='1rem'/> Configurações
                                </Link>
                                <Dropdown.Divider/>
                            </>
                        }
                        <Dropdown.Item 
                            as='button'
                            id="button-logout" 
                            onClick={()=>{logout()}}
                        >
                            Sair <AiOutlineLogout size='1.25rem'/>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </HeaderStyled>
    )
}
import React, { useEffect, useState } from "react";
import { FooterContent } from "./styles"
import { IoIosClose } from 'react-icons/io';
import { BsGraphUpArrow, BsHouseDoorFill, BsPeople } from "react-icons/bs";
import { RiDashboard2Line } from "react-icons/ri";
import { BiMapAlt } from "react-icons/bi";
import { FaCalendarAlt, FaClipboardList, FaCog, FaDatabase, FaFileAlt, FaMapMarkerAlt } from "react-icons/fa";
import { GrHelpBook } from "react-icons/gr";
import { IPermissionsUserAccess, IPropsActionPermissions } from "@/utils/types";
import { useMain } from "@/hooks/main";
import { MdMenuBook } from "react-icons/md";
import { stepByStep } from "@/utils/steps";
import { getCookie, setCookie } from "@/utils/cookies";
import { usePathname } from "next/navigation";
import { parseCookies } from "nookies";

interface IPropsFooter {
  statetutorial: boolean;
  showtutorial?: (value: boolean) => void;
  hasPermission: (data: IPropsActionPermissions) => boolean;
  permissiosThisModule: IPermissionsUserAccess[]
}

export default function Footer({ statetutorial, showtutorial, hasPermission, permissiosThisModule }: IPropsFooter) {
    const pathrouter = usePathname()
    const { infoUser} = useMain();
    const [openTutorial, setOpenTutorial] = useState(false);
    const anoAtual = 2024
    const anoPresente = new Date().getFullYear()
    const { acceptTermsCookies } = parseCookies();

    const rotasValidation : any = {
      '/': 'home',
      '/equipes': 'teams',
      //cspell:disable-next-line
      '/ocorrencias': 'event',
      '/indicadores': 'kpis',
      '/metas': 'okrs',
      '/mapas': 'sector',
      '/marcadores': 'dataMarker',
      '/configuracoes': 'settings',
      '/analises': 'dataAnalytic',
      'dashboard': '/dashboard',
    }

    const handleTutorial = (nameModule: string) => {
      if (showtutorial === undefined) {
        return;
      }

      const rotas : any = {
        'teams': '/equipes',
        //cspell:disable-next-line
        'inicio': '/',
        'dashboard': '/dashboard',
        'event': '/ocorrencias',
        'kpis': '/indicadores',
        'okrs': '/metas',
        'sector': '/mapas',
        'dataMarker': '/marcadores',
        'settings': '/configuracoes',
        'dataAnalytic': '/analises',
      }

      const isPathnameEqualToModule = (nameModule: string) => {    
        const {pathname} = window.location;
    
        if(window.innerWidth < 992 && pathname !== rotas[nameModule] && nameModule !== '' && nameModule !== 'first-time') {
          const buttonOpenMobile : any = document.querySelector('.button-show-menu-mobile')
          if(buttonOpenMobile) {
            buttonOpenMobile.click()
          }
        }
    
        return pathname === rotas[nameModule];
      }

      const topPage = document.querySelector('#header-content')
      if(topPage) {
        const main = topPage.nextElementSibling;
        if(main) {
          main.scrollTop = 0
        }
      }
      //document.documentElement.scrollTop = 0;

      setOpenTutorial(false)
      showtutorial(!statetutorial);

      const isPathnameEqual = isPathnameEqualToModule(nameModule);
      setTimeout(() => {
        stepByStep(nameModule, isPathnameEqual, infoUser, hasPermission, permissiosThisModule);
      }, 250)
    }

    useEffect(() => {
      const firstStep = getCookie('firstSteps');
      const parseCookieFirstStep = JSON.parse(firstStep || '{}');
      
      setTimeout(() => {
        if(acceptTermsCookies && !parseCookieFirstStep[rotasValidation[pathrouter]]) {
          handleTutorial(rotasValidation[pathrouter]);
          setCookie('firstSteps', JSON.stringify({ ...parseCookieFirstStep, [rotasValidation[pathrouter]]: true }));
        }
      }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathrouter, acceptTermsCookies]);

    return (
        <FooterContent as='div' id='footer-content'>
            <div className="tutorial">
                {infoUser.has_team && openTutorial &&
                  <div className="window-tutorial-options">
                    <div className="header-window-tutorial">
                      <div className="title-legend">
                        <h6>Tutorial de usabilidade</h6>
                        <span>Escolha um dos tutoriais abaixo</span>
                      </div>
                      <IoIosClose 
                        size={24} 
                        onClick={() => setOpenTutorial(false)} 
                        cursor='pointer'
                      />
                    </div>
                    <ul>
                      <li onClick={() => handleTutorial("first-time")}><MdMenuBook /> Primeiros passos</li>
                      <li onClick={() => handleTutorial("inicio")}><BsHouseDoorFill /> Inicio</li>
                      {
                        (hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'dashboard'})) &&
                          <li onClick={() => handleTutorial("dashboard")}><RiDashboard2Line /> Dashboard</li>
                      }
                      {
                        (hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'sector'})) &&
                          <li onClick={() => handleTutorial("sector")}><BiMapAlt /> Mapa da propriedade</li>
                      }
                      {
                        (hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'kpi'})) &&
                          <li onClick={() => handleTutorial("kpis")}><BsGraphUpArrow /> Indicadores de performance</li>
                      }
                      {
                        (hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'team'})) &&
                          <li onClick={() => handleTutorial("teams")}><BsPeople /> Equipes</li>
                      }
                      {
                        (hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'dataMarker'})) &&
                          <li onClick={() => handleTutorial("dataMarker")}><FaMapMarkerAlt /> Marcadores de dados</li>
                      }
                      {
                        (hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'event'})) &&
                          <li onClick={() => handleTutorial("event")}><FaCalendarAlt /> Ocorrências</li>
                      }
                      {
                        (hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'okr'})) &&
                          <li onClick={() => handleTutorial("okrs")}><FaClipboardList /> Metas</li>
                      }
                      {/* {
                        (hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'report'})) &&
                          <li onClick={() => handleTutorial("registers")}><FaFileAlt /> Registros</li>
                      } */}
                      {
                        (hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'dataAnalytic'})) &&
                          <li onClick={() => handleTutorial("dataAnalytic")}><FaDatabase /> Analise de Dados</li>
                      }
                      {
                        Object.keys(infoUser).length !== 0 && infoUser.teamSelected?.is_admin &&
                          <li onClick={() => handleTutorial("settings")}><FaCog /> Configurações</li>
                      }
                    </ul>
                  </div>
                }
                {infoUser.has_team &&
                  <div id="menu-tutorial" onClick={() => setOpenTutorial(!openTutorial)} title="Tutorial de usabilidade"><GrHelpBook /> Tutorial</div>
                }
            </div>
            <a href='https://interjato.com.br' title='Ir para página do Grupo Interjato' aria-label='Ir para página do Grupo Interjato' target='_blank' rel='noopener'>
                GRUPO INTERJATO © {(anoAtual === anoPresente) ? (' ' + anoAtual) : (' ' + anoAtual + "-" + anoPresente)}
            </a>
            <div></div>
        </FooterContent>
    )
    
}
import styled, { keyframes } from 'styled-components';

interface IPropsWrapper {
  width?: string;
  height?: string;
}

const openmodal = keyframes`
  from {
    margin-top: 25px;
  }
  to {
    margin-top: 0;
  }
`;

const modal = keyframes`
  from { opacity: .2; }
  to { opacity: 1; }
`;

export const Container = styled.div`
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0,0,0, .5);
  animation: ${modal} .2s ease both;
`;

export const Wrapper = styled.div<IPropsWrapper>`
  margin: 0 .9375rem;
  position: relative;
  max-width: 828px;
  width: ${({ width }) => width ? width : '70%'};
  min-height: ${({ height }) => height ? height : '50%'};

  background-color: #FFF;

  border-radius: 15px;

  animation: ${openmodal} .2s ease both;

  .header {
    display: flex;
    justify-content: space-between;

    padding: 24px 25px 18px 25px;

    border-bottom: 1px solid #000;

    h4 {
      font-size: 1.4rem;
    }

    span {
      cursor: pointer;
    }
  }

  .modal-body {
    overflow-y: auto;
    max-height: calc(100vh/2);
  }

  .button-content {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;

    button {
      background-color: var(--primary-dark);
      color: var(--dark);
      transition: all 0.5s;
      padding: .3125rem;
      border-radius: .625rem;
      border: 1px solid var(--primary-dark);

      &:hover, &:focus, &:active {
        background-color: var(--primary);
        border-color: var(--primary);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
`;
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { parseCookies } from 'nookies'
import { setCookie } from "@/utils/cookies";

import { decodeAES, forceLogout, parseJwt } from "@/utils/helpers";
import { useMain } from "./main";
import { aglutApi, marketplaceApi, predictionApi, priceClimateDataApi, samanauApi } from "@/services/APIs";

type AuthContextType = {
  isAuthenticated: boolean;
  subid: string;
  userName: string;
  tokenUser: string;
  setTokenUser: (value: string) => void;
  setSubId: (value: string) => void;
  setUserName: (value: string) => void;
  logout: () => void;
  setIsAuthenticated: (value: boolean) => void;
}

const AuthContext = createContext({} as AuthContextType)

const AuthProvider = ({ children }: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [subid, setSubId] = useState("");
  const [userName, setUserName] = useState("");
  const [tokenUser, setTokenUser] = useState("");

  const { infoUser, setInfoUser, handleGetPermissionsModules } = useMain();

  const logout = () => {
    const {origin} = window.location;
    setCookie('kcToken', '', {expires: -1})
    setCookie('kcTokenR', '', {expires: -1});
    setCookie('1n3o', '', {expires: -1});
    setCookie('ojbn4tf', '', {expires: -1});
    setCookie('3xp1r3', '', {expires: -1});

    const { 'idToken': idToken } = parseCookies();
    const decodeIdToken = idToken.includes("U2FsdGV") ? decodeAES(idToken) : idToken;

    setCookie('idToken', '', {expires: -1});
    setIsAuthenticated(false);
    window.location.href=`${process.env.NEXT_PUBLIC_KEYCLOAK_URL_LOGOUT}?client_id=${process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID}&post_logout_redirect_uri=${origin}/callback&id_token_hint=${decodeIdToken}`;
  };

  const verifiedToken = useCallback(() => {
    const {origin} = window.location
    setCookie('kcToken', '', {expires: -1});
    window.location.href=`${process.env.NEXT_PUBLIC_KEYCLOAK_ISSUE}${process.env.NEXT_PUBLIC_KEYCLOAK_AUTH}${origin}/callback`;
  },[]);

  useEffect(() => {
    const {origin} = window.location
    const baseUrl = `${origin}/api/v1`;
    aglutApi.defaults.baseURL = baseUrl;
    if(origin.includes('localhost')) {
      aglutApi.defaults.baseURL = 'http://teste.aglut.zaori.local/api/v1';
    }

    const { 'kcToken': token } = parseCookies();

    if(window.location.pathname === "/invited") return;

    if (token !== undefined) {
      const decryptedToken = token.includes("U2FsdGV") ? decodeAES(token) : token;

      const tokenExp = parseJwt(decryptedToken).exp;
      const dtNow = new Date();
      const dtCok = new Date(tokenExp*1000);
          
      if(dtCok < dtNow) {
        verifiedToken();
      }
      
      // Set token
      aglutApi.defaults.headers['Authorization'] = `Bearer ${decryptedToken}`;
      priceClimateDataApi.defaults.headers['Authorization'] = `Bearer ${decryptedToken}`;
      predictionApi.defaults.headers['Authorization'] = `Bearer ${decryptedToken}`;
      marketplaceApi.defaults.headers['Authorization'] = `Bearer ${decryptedToken}`;
      samanauApi.defaults.headers['Authorization'] = `Bearer ${decryptedToken}`;
      
      setTokenUser(decryptedToken);
      setIsAuthenticated(true);
      handleGetPermissionsModules();
    }

    if(!window.location.pathname.includes('/callback') || window.location.pathname === '/callback') {
      setTimeout(()=>{
        const { 'kcToken': token } = parseCookies();
        if(!token || token.length === 0) {
          window.location.href=`${process.env.NEXT_PUBLIC_KEYCLOAK_ISSUE}${process.env.NEXT_PUBLIC_KEYCLOAK_AUTH}${origin}/callback`;
        }
      },250)
    }
  },[]); 

  useEffect(() => {
    if (Object.keys(infoUser).length !== 0 && infoUser.teams.length === 0) {
      forceLogout(); 
    }
  }, [infoUser]);
  return (
    <AuthContext.Provider 
      value={{ 
        isAuthenticated,
        subid,
        setSubId,
        setTokenUser,
        userName,
        tokenUser,
        setUserName,
        logout,
        setIsAuthenticated
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

function useAuthProv(): AuthContextType {
  const context = useContext(AuthContext);

  if(!context) {
    throw new Error("useMain must be used within an MainProvider");
  }

  return context;
}

export { AuthProvider, useAuthProv };
import styled from "styled-components";

export const DropdownContent = styled.div`
    .dropdown-services {
        .dropdown-toggle {
            padding: 0;
            border: .0625rem solid var(--dark);
            background-color: var(--light);
            color: var(--dark);
            transition: all 0.5s;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.8125rem;
            height: 2.8125rem;
            border-radius: .625rem;

            &:hover, &:focus, &:active {
                background-color: var(--primary-dark);
            }

            &::after {
                display: none;
            }
        }
        
        .dropdown-menu {
            .menu-services {
                padding: .375rem .9375rem;
                gap: 0.3rem;
                display: grid;
                grid-template-columns: 1fr 1fr;

                a.item-service {
                    text-decoration: none;
                    width: 6.625rem;
                    height: 5.125rem;
                    border-radius: .625rem;
                    display: flex;
                    gap: 0.5rem;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &:hover, &:focus, &:active, &.active {
                        background-color: var(--primary);
                        color: var(--dark);
                    }
                }
            }
        }
    }
`
'use client'
import { Spinner } from "react-bootstrap";
import { MainLoadingPage } from "./styled";
import React from "react";

export default function LoadingPage() {
    return (
        <MainLoadingPage>
            <h5 className="mb-3">{'Verificando seu acesso a está rota...'}</h5>
            <Spinner animation="border" role="status" size="sm"/>
        </MainLoadingPage>
    )
}

import { BsHouseDoorFill, BsGraphUpArrow, BsPeople } from 'react-icons/bs'; 
import { BiMapAlt } from 'react-icons/bi'; 
import {FaCalendarAlt, FaClipboardList, FaDatabase, FaFileAlt, FaFileArchive, FaMapMarkerAlt} from 'react-icons/fa';
import { RiDashboard2Line } from "react-icons/ri";
import { AiOutlineCloudUpload } from 'react-icons/ai';

export interface ISubmenu {
  id: number;
  label: string;
  path: string;
  icon: JSX.Element;
  module: string;
  submenu?: ISubmenu[];
}

interface IMenu extends ISubmenu {
  submenu?: ISubmenu[]
}

export const Menu: IMenu[] = [
  {
    id: 1,
    label: 'Início',
    path: '/',
    icon: <BsHouseDoorFill size={20} />,
    module: "inicio",
  },
  {
    id: 2,
    label: 'Dashboard',
    path: '/dashboard',
    icon: <RiDashboard2Line size={20} />,
    module: "dashboard",
  },
  {
    id: 3,
    label: 'Mapa',
    path: '/mapas',
    icon: <BiMapAlt size={20} />,
    module: "sector"
  },
  {
    id: 4,
    label: "Indicadores de performance",
    path:'/indicadores',
    icon: <BsGraphUpArrow size={20} />,
    module: "kpi",
  },
  {
    id: 5,
    label: "Equipes",
    path:'/equipes',
    icon: <BsPeople size={20} />,
    module: "team",
  },
  {
    id: 6,
    label: "Marcadores de dados",
    path:'/marcadores',
    icon: <FaMapMarkerAlt size={20} />,
    module: "dataMarker",
  },
  {
    id: 7,
    label: "Ocorrências",
    path:'/ocorrencias',
    icon: <FaCalendarAlt size={20} />,
    module: "event",
  },
  {
    id: 8,
    label: "Metas",
    path:'/metas',
    icon: <FaClipboardList size={20} />,
    module: "okr",
  },
  // {
  //   id: 9,
  //   label: "Registros",
  //   path:'',
  //   icon: <FaFileAlt size={20} />,
  //   module: "okr",
  //   submenu: [
  //     {
  //       id: 1,
  //       label: 'Importação de dados',
  //       path: '/registros/importacao',
  //       icon: <AiOutlineCloudUpload size={20} />,
  //       module: "importData"
  //     },
  //     // {
  //     //   id: 2,
  //     //   label: 'Relatórios',
  //     //   path: '/relatorios',
  //     //   icon: <FaFileArchive size={20} />,
  //     //   module: "reports"
  //     // },
  //   ]
  // },
  {
    id: 10,
    label: "Analise de Dados",
    path:'/analises',
    icon: <FaDatabase size={20} />,
    module: "dataAnalytic",
  },
];


export const MockMarketplace = [
  {
    id: 7,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 01",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 8,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 02",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 9,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 03",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 10,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 04",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 11,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 05",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 12,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 06",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 13,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 07",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 14,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 08",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 15,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 09",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 16,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 10",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 17,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 11",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 18,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 12",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 19,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 13",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 20,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 14",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 21,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 15",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 22,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 16",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 23,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 17",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 24,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 18",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 25,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 19",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 26,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 20",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
  {
    id: 27,
    category: {
        id: 11,
        parent: {
            id: 9,
            is_active: true,
            name: "Dados Ambientais",
            slug: null,
            path_image_category: null,
            description: "Dados Ambientais"
        },
        is_active: true,
        name: "Dados de Qualidade do Ar",
        slug: null,
        path_image_category: null,
        description: "Dados de Qualidade do Ar"
    },
    is_active: true,
    creation_date: "2023-06-06T13:29:19.505205Z",
    name: "dado mock 21",
    product_file: null,
    description: "Dados publicos coletados do SINDA",
    path_image_product: null,
    path_ckan: null,
    sku: null,
    virtual: true,
    downloadable: null,
    download_limite: null,
    expiration: null,
    price: "100.00",
    min_price: null,
    max_price: null,
    product_sale_type: "NORMAL_PRICE",
    stock_quantity: null,
    stock_status: "IN_STOCK",
    average_rating: null,
    total_sales: null,
    store: null,
    owner: null,
    tax: null
  },
]

'use client'

import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'react-datepicker/dist/react-datepicker.min.css'
//import 'bootstrap/dist/js/bootstrap.min.js'
import 'leaflet/dist/leaflet.css'
import "leaflet-draw/dist/leaflet.draw.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-circular-progressbar/dist/styles.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import '@/utils/leaflet_awesome_markers/leaflet.awesome-markers.css'
import '@/assets/fonts/stylesheet.css';
import '@/styles/globals.css';
import { MainProvider } from '@/hooks/main';
import StyledComponentsRegistry from './registry';
import { ToastContainer } from 'react-toastify';
import AppProvider from '@/hooks';
import { AuthProvider } from '@/hooks/AuthHook';
import { SliderTimeProvider } from '@/hooks/SliderTimer';
import Theme from '@/components/Theme';
import Script from 'next/script';
import React from 'react';
import { PrivateRouterProvider } from '@/hooks/PrivateRouters';



export default function RootLayout({
    children,
}: {
    children: React.ReactNode
}) {

    React.useEffect(() => {
        require('bootstrap/dist/js/bootstrap.min.js');
        require('@fortawesome/fontawesome-free/js/all.min.js');
        require('react-datepicker/dist/react-datepicker.min.js');
    }, [])
    return (
        <html lang="pt-br">
            <head>
                <title>Aglut</title>
                <meta name="description" content="Monitoramento e Gerenciamento de fazendas e culturas para o agricultor" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta data-n-head="ssr" data-hid="keywords" name="keywords" content="Agro, Fazenda, AgroMonitor, Agricultor, Culturas, Gerenciamento, Monitoramento, Agricultura de precisão, Monitoramento agrícola, Manejo do solo, Eficiência agrícola, Dados agrícolas, Análise de dados, Camadas de dados, Indicadores de performance, Dashboard agrícola, Tomada de decisão, Rentabilidade, Tecnologia agrícola, Gerenciamento de operações, Informações em tempo real, Clima e tempo, Colheita, Irrigação, Fertilização, Sustentabilidade." />
                <meta data-n-head="ssr" data-hid="theme-color" name="theme-color" content="#199473" />
                <meta data-n-head="ssr" data-hid="og:image" name="og:image" content="../assets/logo-name-next-to.png" />
                <meta data-n-head="ssr" data-hid="og:image:width" name="og:image:width" content="273" />
                <meta data-n-head="ssr" data-hid="og:image:height" name="og:image:height" content="273" />
                <meta data-n-head="ssr" data-hid="designer" name="designer" content="Atyson Jaime, Léonardo Lopes, Jesiel Carlos, Eduardo De Moura e Yan Barros <https://interjato.com.br/> " />
                <meta data-n-head="ssr" data-hid="og:type" name="og:type" content="system" />
                <meta data-n-head="ssr" data-hid="og:title" name="og:title" content="AgroMonitor" />
                <meta data-n-head="ssr" data-hid="og:description" name="og:description" content="Monitoramento e Gerenciamento de fazendas e cultuas para o agricultor" />
                {/* TODO: Adicionar depois a url quando tiver o https */}
                <meta data-n-head="ssr" data-hid="og:url" name="og:url" content="" />
                <meta data-n-head="ssr" data-hid="og:site_name" name="og:site_name" content="AgroMonitor" />
                {/* TODO: Adicionar depois a url quando tiver o https */}
                <meta data-n-head="ssr" data-hid="copyright" name="copyright" content="" />
                <meta data-n-head="ssr" data-hid="category" name="category" content="Sistema" />
                <meta data-n-head="ssr" data-hid="robots" name="robots" content="index,follow" />
                <link data-n-head="ssr" rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link data-n-head="ssr" rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link data-n-head="ssr" rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="icon" href='/favicon.ico' />
                <link as="style" rel="stylesheet preload prefetch" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css" />
            </head>
            <body>
                <MainProvider>
                    <ToastContainer
                        position="top-right"
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                        limit={1}
                    />
                    <StyledComponentsRegistry>
                        <AppProvider>
                            <AuthProvider>
                                <PrivateRouterProvider>
                                    <SliderTimeProvider>
                                        <Theme>
                                            {children}
                                        </Theme>
                                    </SliderTimeProvider>
                                </PrivateRouterProvider>
                            </AuthProvider>
                        </AppProvider>
                    </StyledComponentsRegistry>
                </MainProvider>
                <Script defer src="https://cdn.jsdelivr.net/npm/leaflet@1.1.0/dist/leaflet.js"></Script>
            </body>
        </html>
    )
}

import styled from "styled-components";

export const SidebarContainer = styled.div`
    @media (max-width: 992px) {
        .aside-container {
            display: none;
        }
    }
`

export const AsideContainer = styled.div`
    height: 100vh;
    background-color: var(--dark);
    /* width: 15.625rem; */
    display: flex;
    flex-direction: column;
    position: relative;
    transition: width 0.5s;

    .button-close {
        position: absolute;
        right: 0;
        background-color: transparent;
        box-shadow: none;
        padding: 0.3rem;
        border: 0;
        transition: all 0.5s;

        svg {
            color: var(--light);
        }
    }

    .logo-zaori {
        height: 4.450rem;
        border-bottom: 1px solid var(--light);
        display: flex;
        width: 100%;
        padding: 1rem;
        
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        @media (max-width: 992px) {
            border: none;
        }
    }

    .content-menu {
        height: 100vh;
        width: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--gray);
            border-radius: 4px;
            /* border: 2px solid #ebebeb; */
        }

        ul {
            list-style: none;
            padding: 0;
            
            a {
                display: flex;
                font-size: var(--tam-h6);
                text-decoration: none;
                width: 100%;
                color: var(--light);
                align-items: center;
                gap: 1rem;
                padding: 20px 15px;
                border-bottom: 1px solid var(--light);
                cursor: pointer;
                transition: all 0.5s;

                .name-content {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                }

                &:hover, &:focus, &:active, &.active {
                    background-color: var(--primary-dark);
                    box-shadow: none;
                    outline: none;
                    color: var(--dark);
                }
            }

            li ul.submenu-content {
                background-color: rgba(79, 79, 79, 8);

                li.submenu-item {
                    a {
                        border-bottom: 1px solid rgba(245, 245, 245, .2);

                        &:hover, &:focus, &:active, &.active {
                            background-color: rgba(243, 216, 86, .6);

                            svg {
                                fill: var(--dark);
                            }
                        }
                    }

                    &:last-child a {
                        border-bottom: 1px solid var(--primary-dark);
                    }
                    a .submenu-name svg {
                        margin-right: 8px;
                    }


                }
            }
        }

        /* @media (max-width: 992px) {
            height: calc(100vh - (226px + 46px));
        } */
    }

    .footer-layout {
        height: 4.6rem;
        width: 100%;
        position: relative;
        margin-top: auto;
        border-top: 1px solid #fff;

        .bloco_top {
            background-color: var(--primary-dark);
            position: absolute;
            width: 3.3125rem;
            height: 1.8rem;
            top: 0;
            left: 21%;

        }
        .bloco_bottom_left {
            position: absolute;
            width: 3.3125rem;
            height: 1.8rem;
            bottom: 0;
            left: 0;
            background-color: var(--primary-dark);
        }
        .bloco_bottom_right {
            background-color: var(--primary-dark);
            width: 9rem;
            height: 1.8rem;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    &.hide-menu {
        width: 5rem;

        .content-menu {
            height: 100vh;

            ul {
                a {
                    justify-content: center;

                    svg {
                        width: 28px;
                        height: 28px;
                    }

                    .name-content, .submenu-name {
                        display: none;
                    }
                }
            }
        }

        .footer-layout {
            height: 4.6rem;
            margin-top: auto;
            border-top: 1px solid #fff;

            .bloco_top {
                background-color: var(--primary-dark);
                position: absolute;
                width: 25px;
                height: 25px;
                top: 0;
                left: 33%;

            }
            .bloco_bottom_left {
                position: absolute;
                width: 25px;
                height: 25px;
                bottom: 0;
                left: 0;
                background-color: var(--primary-dark);
            }
            .bloco_bottom_right {
                background-color: var(--primary-dark);
                width: 28px;
                height: 25px;
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }
    }
`

export const SelectTeam = styled.div`
    select {
        background-color: transparent;
        color: var(--light);
        border: none;
        border-bottom: 1px solid var(--light);
        border-top: 1px solid var(--light);
        border-radius: 0;
        min-height: 2.875rem;

        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");

        &:focus, &:active {
            outline: none;
            box-shadow: none;
            border-color: var(--primary-dark);
        }

        option {
            color: var(--dark);

            &:hover {
                background-color: var(--dark)!important;
            }
        }
    }

    @media (max-width: 576px) {
        display: block;
    }
`
import RotaPrivada from "@/components/RotaPrivada";
import React, {
  createContext,
  useContext,
} from "react";
import { useAuthProv } from "./AuthHook";
import LoadingPage from "@/components/LoadingPage";
import { useMain } from "./main";
import { Menu } from "@/utils/menu";
import { toast } from "react-toastify";
type router = {
  pathname: string;
  code: string;
};

type PrivateRouterType = {};

const PrivateRouterContext = createContext({} as PrivateRouterType);

const PrivateRouterProvider = ({ children }: any) => {
  const { isAuthenticated } = useAuthProv();
  const { infoUser } = useMain();
  const [isPrivateRouter, setIsPrivateRouter] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const { pathname, search } = window.location;
    if (pathname === "/" || pathname === "/invited") {
      setIsPrivateRouter(true);
      setIsLoading(false);
      return;
    }

    if (pathname === "/callback") {
      setIsPrivateRouter(true);
      if (search === "") {
        toast.success("Redirecionando para tela de login", {
          autoClose: false,
        });
        setTimeout(() => {
          setIsLoading(false);
        }, 260);
      } else {
        setIsLoading(false);
      }
      return;
    }

    if (Object.keys(infoUser).length === 0) return;
    
    if (pathname === "/perfil") {
      setIsPrivateRouter(true);
      setIsLoading(false);
      return;
    }
    
    if(infoUser.teamSelected?.is_admin) {
      if(pathname === '/configuracoes') {
        setIsPrivateRouter(true);
        setIsLoading(false);
        return;
      }
    }

    const myRoutersPermitted = infoUser.teamSelected?.system_modules?.filter(
      (item) => {
        if (item.permissions.length === 0) return;

        return Menu.filter((code) => {
          if (item.is_active && code.module === item.code) return;
        });
      }
    );

    const routersSistem: router[] = [
      { pathname: "/equipes", code: "team" },
      { pathname: "/indicadores", code: "kpi" },
      { pathname: "/mapas", code: "sector" },
      { pathname: "/marcadores", code: "dataMarker" },
      { pathname: "/metas", code: "okr" },
      { pathname: "/analises", code: "dataAnalytic" },
      { pathname: "/ocorrencias", code: "event" },
      { pathname: "/relatorios", code: "report" },
      { pathname: "/registros", code: "attachments" },
      { pathname: "/", code: "dashboard" },
      { pathname: "/dashboard", code: "dashboard" },
    ];

    let path = pathname === undefined ? "/" : pathname;
    if (pathname.split("/").length >= 2) {
      path = "/" + pathname.split("/")[1];
    }

    const path_code = routersSistem?.filter((router) => {
      if (router.pathname === path) {
        return router;
      }
    });

    const privateRouters2: router[] = (myRoutersPermitted ?? []).map((item) => {
      return {
        pathname: item.code,
        code: item.code,
      };
    });

    async function checkPrivateRouter(pathname: string) {
      if (pathname === "/" || pathname === "/callback") return true;

      if (!isAuthenticated) {
        if (pathname === "/") {
          setIsLoading(false);
          return false;
        }

        let routerEqual = 0;
        privateRouters2?.forEach((router: router) => {
          if (router.pathname.includes(pathname)) {
            routerEqual = routerEqual + 1;
          }
        });

        if (routerEqual > 0) return true;
      }

      const hasPermission = privateRouters2?.filter((router) => {
        if (router.code === pathname) {
          return true;
        }
      });

      if (hasPermission.length > 0) return true;

      return false;
    }

    checkPrivateRouter(path_code.length === 0 ? path : path_code[0].code)
      .then((res) => {
        setIsPrivateRouter(res);
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      })
      .catch((erro) => {
        toast.error(
          "Erro ao verificar seu acesso a esta rota. Por favor, entre em contato com o suporte.",
          {
            autoClose: 7000,
          }
        );
      });
  }, [isAuthenticated, infoUser]);

  return (
    <PrivateRouterContext.Provider value={{}}>
      {isLoading ? (
        <LoadingPage />
      ) : isPrivateRouter ? (
        children
      ) : (
        <RotaPrivada />
      )}
    </PrivateRouterContext.Provider>
  );
};

function usePrivateRoutersProv(): PrivateRouterType {
  const context = useContext(PrivateRouterContext);

  if (!context) {
    throw new Error("useMain must be used within an MainProvider");
  }

  return context;
}

export { PrivateRouterProvider, usePrivateRoutersProv };

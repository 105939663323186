import { Button } from "react-bootstrap";
import { MainPrivateRouter } from "./styled";
import { useRouter } from "next/navigation";

import { PiWarning } from "react-icons/pi";

export default function RotaPrivada() {
    return (
        <MainPrivateRouter>

            <div className="content">

                <h3><PiWarning size={54} /> Aviso</h3>
                <h5>Você não tem permissão para acessar essa página.</h5>

                
                <Button onClick={() => window.location.href = '/'}>Voltar</Button>
            </div>
            
        </MainPrivateRouter>
    )
}
import styled, { keyframes } from "styled-components";

const opentutorial = keyframes`
  0% {
    left: -25px;
  }
  50% {
    left: 15px;
  }
  100% {
    left: 0;
  }
`;

export const FooterContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 4.375rem;
    min-height: 4.375rem;
    padding: .9375rem 1.875rem;
    background-color: var(--light);
    color: var(--dark);
    font-weight: 500;
    filter: drop-shadow(0px -4px 4px rgba(0, 0, 0, 0.25));
    gap: 1rem;

    @media (max-width: 576px) {
        padding: .9375rem;
    }

    .tutorial {
        position: relative;
        
        .window-tutorial-options {
            position: absolute;
            bottom: 30px;
            left: 0;

            width: 300px;
            padding: 1rem 1rem .3rem;
            background-color: #FFF;
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            background-color: var(--primary-dark);

            .header-window-tutorial {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                
                .title-legend {
                    
                    h6 {
                        font-weight: bold;
                    }

                    span {
                        font-size: 14px;
                        font-weight: 400;
                        color: rgba(54, 54, 54, .8);
                    }
                }
            }

            ul {
                list-style: none;
                margin-top: .8rem;
                padding-left: 0;

                li {
                    font-weight: 400;
                    color: var(--dark);
                    line-height: 26px;
                    cursor: pointer;
                    transition: all .2s ease;

                    &:hover {
                        font-weight: bold;
                        padding-left: 8px;
                        background-color: rgba(0, 0, 0, 0.080);
                    }
                    &:not(:last-child) {
                        border-bottom: 1px dashed rgba(0, 0, 0, 0.090);
                    }

                    svg {
                        margin-right: 10px;
                    }
                }
            }

            animation: ${opentutorial} .2s ease both;
        }

        #menu-tutorial {
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;
            
            &:hover {
                text-decoration: underline;
            }
        }
    }

    a {
        border-bottom: 1px solid transparent;
        transition: all 0.5s;
        text-decoration: none;
        color: var(--dark);

        &:hover, &:focus {
            border-bottom: 1px solid var(--dark);
        }
    }
`

import Image from "next/image";
import { AsideContainer, SelectTeam, SidebarContainer } from "./styled";

import LogoZaori from "@/assets/logo_zaori_branco.svg"
import IconZaori from "@/assets/icone_zaori_branco.svg"
import Link from "next/link";
import { ISubmenu, Menu } from '@/utils/menu';
import React, { ChangeEvent, ReactElement } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { AiOutlineClose, AiOutlineConsoleSql } from "react-icons/ai";
import {IPermissionsUserAccess } from "@/utils/types";
import { useMain } from "@/hooks/main";
import { BiSubdirectoryRight } from "react-icons/bi";
import { encodeAES } from "@/utils/helpers";
import { setCookie } from "@/utils/cookies";

interface Props {
    showMenu: boolean,
    hideMenu: boolean,
    setShowMenu: (showMenu: boolean) => void
}

type menuItem = {
    id: number;
    label: string;
    path: string;
    icon: JSX.Element;
    module: string;
    submenu?: ISubmenu[];
}

export default function Sidebar({ showMenu, setShowMenu, hideMenu }: Props) {
    const { infoUser, handleGetPermissionsModules, changedRouter } = useMain()
    const [modules, setModules] = React.useState<IPermissionsUserAccess[]>([]);
    const [selected, setSelected] = React.useState<number>(1);

    const handlePermissionModules = () => {
      const modules = infoUser.teamSelected ? infoUser.teamSelected?.system_modules : [];
      setModules(modules);
    }

    const handleTeam = React.useCallback((e: ChangeEvent<HTMLSelectElement>) => {
        const idTeam = Number(e.target.value);
        setCookie('idTeam', encodeAES(idTeam.toString()))
        setTimeout(() => {
            handleGetPermissionsModules(idTeam);
        }, 250)
    }, []);

    React.useEffect(() => {
        handlePermissionModules();
        const router = window.location.pathname.split('/')
        let linkActive: any = Menu[0];
        if (window.location.pathname !== '/' && window.location.pathname !== '/callback') {
            [linkActive] = Menu.filter(item => item.path.includes(router[1]))
        }
        if (linkActive !== undefined && linkActive.hasOwnProperty('id')) {
            setSelected(linkActive.id)
        }
        if (window.location.pathname === '/perfil' || window.location.pathname === '/configuracoes') {
            setSelected(1000)
        }
    }, [changedRouter])

    return (
        <>
            <SidebarContainer>
                <AsideContainer data-cy='aside' as='div' className={hideMenu ? "aside-container hide-menu" : "aside-container"}>
                    <div className="logo-zaori">
                        <Image priority src={hideMenu ? IconZaori.src : LogoZaori.src} alt="Logo Zaori Branca" height={100} width={100} />
                    </div>
                    <ListItemMenu modules={modules} selected={selected} setSelected={setSelected}/>
                </AsideContainer>
                <Offcanvas data-cy='aside-mobile' className='aside-menu-container' show={showMenu} onHide={() => { setShowMenu(false) }}>
                    <Offcanvas.Body>
                        <AsideContainer as='div' className="aside-container">
                            <button className="button-close" type="button" onClick={() => { setShowMenu(false) }}>
                                <AiOutlineClose size={20} />
                            </button>
                            <div className="logo-zaori">
                                <Image priority src={LogoZaori.src} alt="Logo Zaori Branca" height={100} width={100} />
                            </div>
                            {infoUser.has_team &&
                                <SelectTeam>
                                    <Form.Select id='select_team' onChange={(e) => handleTeam(e)} aria-label='Select para troca de equipe' value={infoUser.teamSelected?.id}>
                                        {infoUser.teams?.map((team, key) => (
                                            <option key={key + '-' + team.id} value={team.id}>{team.name}</option>
                                        ))}
                                    </Form.Select>
                                </SelectTeam>
                            }
                            <ListItemMenu modules={modules} selected={selected} setSelected={setSelected}/>
                        </AsideContainer>
                    </Offcanvas.Body>
                </Offcanvas>
            </SidebarContainer>
        </>
    )
}

type TListMenu = {
  modules: IPermissionsUserAccess[],
  selected: number,
  setSelected: (number: number) => void
}
function ListItemMenu({modules, selected, setSelected}: TListMenu) {
  const [selectedSubmenu, setSelectedSubmenu] = React.useState<number>(1);
  const [openSubmenu, setOpenSubmenu] = React.useState<boolean>(false);

  const handleOpensubmenu = (hasSubmenu: number | undefined) => {
    if (!hasSubmenu || hasSubmenu === undefined) return;
    setOpenSubmenu(!openSubmenu);
  }
  
  return (
    <>
      <nav className="content-menu">
        <ul className="menu-list">

            {Menu.map((item: menuItem, index: number) => {
                let hasModule = modules?.filter(mod => mod.code === item.module);
                if (hasModule && hasModule[0]?.permissions && hasModule[0]?.permissions.length > 0 && hasModule[0]?.is_active || item.module === 'inicio') {
                    return (
                        <li className="menu-item" key={item.label + item.path} id={'menu_'+item.module} onClick={() => handleOpensubmenu(item.submenu?.length)}>
                            <Link className={selected === item.id ? "menu-link active" : "menu-link"} href={item.path} title={"Ir para " + item.label} aria-label={"Ir para " + item.label} onClick={() => {setSelected(item.id)}}>

                                <div className="icon-content">
                                    {item.icon}
                                </div>
                                <div className="name-content">
                                    {item.label}
                                    {/* <FiChevronRight size={24} /> */}
                                </div>
                            </Link>
                            {item.submenu && item.submenu?.length > 0 && selected === 9 &&
                                <ul className="submenu-content">
                                    {item.submenu?.map((submenu, index) => (
                                        <li key={index} className="submenu-item">
                                            <Link className={selectedSubmenu === item.id ? "menu-link active" : "menu-link"} href={submenu.path} title={"Ir para " + submenu.label} aria-label={"Ir para " + submenu.label} onClick={() => { setSelectedSubmenu(submenu.id) }}>
                                                <div className="icon-content">
                                                    {item.submenu && item?.submenu[index].icon}
                                                </div>
                                                <div className="submenu-name">
                                                    {/* <BiSubdirectoryRight color="#FCCD12" /> */}
                                                    {submenu.label}
                                                </div>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            }

                        </li>
                    )
                }
            })}
        </ul>
    </nav>
    <div className="footer-layout">
        <div className="bloco_top"></div>
        <div className="bloco_bottom_left"></div>
        <div className="bloco_bottom_right"></div>
    </div>
    </>
  )
}
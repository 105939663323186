import styled from 'styled-components';

interface PropsSection {
  $hideMenu: boolean,
  $initialContent: any
}

export const ContainerDashboard = styled.div<PropsSection>`
  width: 100%;
  height: 100%;
  background-color: var(--light);
  display: grid;
  transition: all 0.5s;
  grid-template-columns: ${props => props.$initialContent ? '1fr' : props.$hideMenu ? '5rem auto' : '15.625rem auto'};

  @media (max-width: 992px) {
      grid-template-columns: 1fr !important;
  }
`

export const ContainerContent = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

export const MainContent = styled.div`
  height: calc(100vh - 4.375rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  overflow: auto;
  scroll-behavior: smooth !important;

  .loading-start {
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    
    .spinner-border {
        width: 5rem;
        height: 5rem;
        font-size: 1rem;
    }
  }
`;

export const TermsPrivacyCookies = styled.div`
  padding: 2rem;
  z-index: 10000;

  .terms-privacy-wrapper {
    overflow-y: auto;
    max-height: 206px;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 8px;
    background-color: #F0F0F0;

    &::-webkit-scrollbar-track {
      background-color: #F4F4F4;
    }
    &::-webkit-scrollbar {
      width: 3px;
      background: #F4F4F4;
    }
    &::-webkit-scrollbar-thumb {
      background: #dad7d7;
    }
  }

  .accept-terms {
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-bottom: 2rem;

    strong {
      color: #295D83;
      font-weight: 400;
    }
  }

  .accept-cookies {
    padding: 1rem;
    border-radius: 8px;
    background-color: #F0F0F0;

    .icon-cookies {
      margin-bottom: 1rem;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }

  .wrapper-buttons {
    display: flex;
    justify-content: flex-end;

    button {
      width: 181px;
      padding: 11px 10px;
      margin-top: 2rem;
      border-radius: 10px;
      border: none;
      background: var(--primary-dark);
      cursor: pointer;
      transition: all .3s ease;

      &:hover {
        opacity: .8;
        box-shadow: 2px 5px 25px rgba(0,0,0, .09);
      }
    }
  }
`;
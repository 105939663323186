import styled from "styled-components";

export const MainLoadingPage = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 1rem;

    .spinner-border {
        width: 10rem;
        height: 10rem;
    }
`
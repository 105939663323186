import React, { ReactNode, useEffect } from 'react';
import {
  ContainerDashboard,
  ContainerContent,
  MainContent,
  TermsPrivacyCookies,
} from './styles';
import { useMain } from '@/hooks/main';
import Sidebar from './Dashboard/Sidebar';
import Header from './Dashboard/Header';
import Footer from './Dashboard/Footer';

import { stepByStep } from '@/utils/steps';
import { IPermissionsUserAccess, SelectedStep } from '@/utils/types';
import Modal from '../Modal';
import { Spinner } from 'react-bootstrap';
import { PiCookieBold } from "react-icons/pi";
import { toast } from 'react-toastify';
import { setCookie } from '@/utils/cookies';
import { parseCookies } from 'nookies';

type Props = {
  children: ReactNode;
}

export default function Theme({ children }: Props) {
  const { infoUser, loadDataInfoUser, hasPermission, handlePermissions } = useMain();
  const [isInvite, setIsInvite] = React.useState<boolean>(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const [hideMenu, setHideMenu] = React.useState(false);

  const [showTutorial, setShowTutorial] = React.useState(false);

  const [modalTerms, setModalTerms] = React.useState(false);

  React.useEffect(() => {
    window?.location.pathname === '/invited' && setIsInvite(true)
  }, [])

  //Permissões
  const [permissiosThisModule, setPermissiosThisModule] = React.useState<IPermissionsUserAccess[]>([]);

  React.useEffect(() => {
    if(Object.keys(infoUser).length === 0) {
      return
    }
    handlePermissions({
      namePage: ['dashboard', 'team', 'sector', 'dataMarker', 'event', 'kpi', 'okr', 'dataAnalytic', 'report'],
      setState: setPermissiosThisModule
    })
    const { acceptTermsCookies, newUserSystem } = parseCookies();
    if(acceptTermsCookies !== 'true') {
      setTimeout(() => {
        setModalTerms(true);
      }, 1000);
    } else {
      if(newUserSystem !== 'false') {
        stepByStep('first-time', false, infoUser, hasPermission, permissiosThisModule);
      }
    }
  },[infoUser])

  function handleClickTerms() {
    setCookie('acceptTermsCookies','true', { expires: 365 });
    setModalTerms(false);
    const {newUserSystem} = parseCookies();
    if(newUserSystem !== 'false' && window.localStorage.pathname !== '/invited') {
      stepByStep('first-time', false, infoUser, hasPermission, permissiosThisModule);
    }
  }

  return (
    <ContainerDashboard id='module-dashboard' as='div' $hideMenu={hideMenu} $initialContent={(isInvite || !infoUser.has_team)}>
      {!isInvite && infoUser.has_team &&
        <Sidebar showMenu={showMenu} setShowMenu={setShowMenu} hideMenu={hideMenu} />
      }
      <ContainerContent>
        <div id="on-top-page"></div>
        <Header
          setShowMenu={setShowMenu}
          hideMenu={hideMenu}
          setHideMenu={setHideMenu}
          isInvited={isInvite}
          hasTeam={infoUser.has_team}
        />
        <MainContent>
          {
            loadDataInfoUser ? (
              <div className='loading-start'>
                Carregando as informações do usuário...
                <Spinner animation="border" />
              </div>
            ) : (
              children
            )
          }
          {/* cspell:disable-next-line */}
          <Footer
            statetutorial={showTutorial}
            showtutorial={setShowTutorial}
            hasPermission={hasPermission}
            permissiosThisModule={permissiosThisModule}
          />
        </MainContent>
      </ContainerContent>
      
      {modalTerms &&
        <Modal
          title='Termos de uso e Política de privacidade'
          onclose={() => setModalTerms(false)}
          showOnClose={false}
        >
          <TermsPrivacyCookies>
            <div className="accept-cookies">
              <div className="icon-cookies"><PiCookieBold size={32} /> Cookies</div>
              <p>O Aglut usa cookies para melhorar sua experiência. Os cookies são pequenos arquivos de texto que são armazenados em seu computador ou dispositivo móvel quando você visita um site. Eles permitem que o site se lembre de suas ações e preferências.</p>
              
              <div className="wrapper-buttons">
                <button onClick={handleClickTerms}>Aceitar</button>
              </div>
            </div>
          </TermsPrivacyCookies>
        </Modal>
      }
    </ContainerDashboard>
  )
}
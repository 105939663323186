import { GrClose } from 'react-icons/gr';
import { ReactNode, useCallback, useState } from 'react';

import {
  Container, Wrapper
} from './styles';
import { useMain } from '@/hooks/main';

interface IProps {
  showOnClose?: boolean;
  onclose: () => void;
  title: string;
  children?: ReactNode;
  width?: string;
  height?: string;
  id?: string;
}

export default function Modal({ onclose, title, children, width, height, id, showOnClose=true }: IProps) {
  const [closeModal, setCloseModal] = useState();
  
  return(
    <Container>
      <Wrapper as='div' width={width} height={height} id={id}>
        <div className="header">
          <h4>{title}</h4>

          {showOnClose &&
            <span onClick={onclose} id={`close-${id}`}>
              <GrClose size={20} />
            </span>
          }
        </div>
        
        <div className="modal-body custom-scrool">
          {children}
        </div>

      </Wrapper>
    </Container>
  )
}
import styled from "styled-components";

export const MainPrivateRouter = styled.main`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        height: 100vh;
        padding: .9375rem;

        h3 {
            display: flex;
            align-items: center;
            gap: 1rem;
        }

        h5 {
            font-weight: 400;
        }

        .buttons-content {
            display: flex;
            gap: 1rem;
            flex-wrap: wrap;
        }
    
        button, a {
            text-decoration: none;
            margin-top: 4rem;
            border-radius: .625rem;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6px 12px;
            background-color: var(--primary-dark);
            color: var(--dark);
            border: 0;
    
            &:hover, &:focus, &:active {
                background-color: var(--primary);
                color: var(--dark);
            }
        }

        @media (max-width: 768px) {
            min-height: calc(100vh - 11.5625rem);
        }
    }
`
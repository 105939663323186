import styled from "styled-components";

interface Props {
    $initialContent: any
}

export const HeaderStyled = styled.div<Props>`
    height: 4.375rem;
    width: 100%;
    display: flex;
    justify-content: ${props => props.$initialContent ? 'end' : 'space-between'};
    align-items: center;
    padding: .9375rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    position: relative;

    .user-actions {
        display: flex;
        align-items: center;
        button {
            margin-right: .625rem;
        }

        .dropdown-user {
            .dropdown-toggle {
                cursor: pointer;
                margin: 0;
                min-height: 2.8125rem;
                display: flex;
                align-items: center;
                background-color: transparent;
                border: none;
                padding: 0;
                
                svg {
                    fill: var(--dark);
                    color: var(--dark);
                }

                .circle {
                    width: 2.8125rem;
                    height: 2.8125rem;
                    border-radius: 100%;
                    background: linear-gradient(225deg, #F3D856 0%, #383838 100%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.5s;

                    svg {
                        fill: var(--light);
                        color: var(--light);
                    }

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                    }
                }

                .infos {
                    margin-left: .625rem;
                    display: flex;
                    flex-direction: column;
                    .name {
                        font-weight: bold;
                    }

                    .name, .role {
                        transition: all 0.5s;
                        margin: 0;
                    }

                    .placeholder-glow {
                        width: 6.25rem;
                        cursor: pointer;
                        transition: all 0.5s;
                    }
                }

                &:hover, &:focus, &:active {
                    box-shadow: none;
                    border: none;
                    outline: none;

                    .circle {
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }

                    .infos {
                        .name, .role, .placeholder-glow {
                            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        }
                    }
                }

                @media (max-width: 576px) {
                    .infos {
                        display: none;
                    }
                }
            }

            .infos-mobile {
                display: flex;
                flex-direction: column;
                padding: 15px;

                .name {
                    font-weight: bold;
                }

                @media (min-width: 576px) {
                    display: none;
                }
            }

            .dropdown-divider-mobile {
                @media (min-width: 576px) {
                    display: none;
                }
            }

            .dropdown-menu {
                z-index: 10000;
                a,button {
                    z-index: 10000;
                    display: flex;
                    align-items: center;
                    transition: all 0.5s;

                    &:hover, &:focus, &:active {
                        background-color: var(--primary);
                    }

                    svg {
                        margin-left: .3125rem;
                    }
                }
            }
        }
    }

    .button-show-menu-mobile {
        display: none;
    }

    .button-hide-menu-names {
        svg {
            transition: all 0.5s;
            transform: rotate(0deg);
        }

        &.hide-menu {
            svg {
                transform: rotate(180deg);
            }
        }
    }

    @media (max-width: 992px) {
        .button-hide-menu-names {
            display: none;
        }

        .button-show-menu-mobile {
            display: flex;
        }
    }
`

export const ButtonIcon = styled.button`
    border: .0625rem solid var(--dark);
    background-color: var(--light);
    color: var(--dark);
    transition: all 0.5s;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.8125rem;
    height: 2.8125rem;
    border-radius: .625rem;

    &:hover, &:focus, &:active {
        background-color: var(--primary);
    }
`

export const SelectTeam = styled.div`
    cursor: pointer;
    select {
        background-color: transparent;
        color: var(--dark);
        border-radius: 0;
        min-height: 2.8125rem;
        height: 100%;
        width: 10rem;
        border-radius: .625rem;
        margin-right: .625rem;
        border: 1px solid var(--dark);
        cursor: pointer;
    
        &:focus, &:active {
            outline: none;
            box-shadow: none;
            border-color: var(--primary);
        }
    }

    @media (max-width: 992px) {
        display: none;
    }
`
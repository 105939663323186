import { useMain } from "@/hooks/main";
import { DriveStep, driver } from "driver.js";
import "driver.js/dist/driver.css";
import { IDataInfoUserCookie, IPermissionsUserAccess, IPropsActionPermissions } from "./types";
import { getCookie, setCookie } from "./cookies";

import { triggerEvent } from 'dom-helpers';
import { wrap } from "module";
import { checkRequestComplete, handleSetFirstStep } from "./helpers";


export const stepByStep = (module: string, isPathnameEqualToModule: boolean, infoUser: IDataInfoUserCookie, hasPermission: (data: IPropsActionPermissions) => boolean, permissiosThisModule: IPermissionsUserAccess[]) => {
  // 'dashboard', 'team', 'sector', 'dataMarker', 'event', 'kpi', 'okr', 'dataAnalytic', 'report'
  // hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'dashboard'})

  const StepsTeam: DriveStep[] = [
    {
      element: window.innerWidth <= 992 ? '.offcanvas #menu_team' : '#menu_team',
      popover: {
        title: 'Equipe', description: 'Clique aqui para acessar o módulo de equipes', 
        onNextClick: () => {
          const btnModuleTeam: any = document.querySelector('#menu_team > a');
          btnModuleTeam.click();
          //next step
          setTimeout(() => {
            if (window.innerWidth <= 992) {
              const buttonCloseMobile: any = document.querySelector('.offcanvas .button-close');
              if (buttonCloseMobile) {
                buttonCloseMobile.click();
              }
            }
            driverObj.moveNext();
          }, 1000);
        }
      }
    },
    {
      element: '#menu_center',
      popover: {
        title: "Página de Equipe",
        description: 'Bem vindo as suas equipes, aqui você tera a visualização de todos as equipes pertencentes ao seu usuário.',
        onNextClick: () => {
          const reqTeam = checkRequestComplete.getRequest({nameRequest: ['teamRequest']});
          const hasRequestNull = reqTeam.filter(req => req === null);
          
          if(hasRequestNull.length === 0) {
            driverObj.moveTo(2);
            return;
          }

          if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'team'})) {
            driverObj.moveNext();
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(6) : driverObj.moveTo(7);
          }
        },
      }
    },
    {
      element: '#alert-team',
      popover: {
        title: 'Atenção',
        description: 'Para uma melhor experiencia aguarde o carregamento completo da página',
        onNextClick: () => {
          driverObj.destroy()
        },
        nextBtnText: 'Fechar',
        disableButtons: ['previous'],
      }
    },
    {
      element: '#team_btn-new-team', 
      popover: {
        title: 'Nova equipe', 
        description: 'Clique no botão para criar uma nova equipe', 
        onNextClick: () => {
          const teamModal: any = document.querySelector('#team_btn-new-team');
          teamModal?.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        },
        onPrevClick: () => {
          driverObj.moveTo(0);
        }
      }
    },
    {
      element: '#team_modal-new-team', 
      popover: {
        title: 'Nova equipe', 
        description: 'Aqui podes criar uma nova equipe',
      }
    },
    { 
      element: '#team_name-team', 
      popover: { 
        title: 'Nova equipe', 
        description: 'Insira o nome da nova equipe' 
      } 
    },
    {
      element: '#team_btn-create', 
      popover: {
        title: 'Criar nova equipe', 
        description: 'Ao clicar no botão Cadastrar uma nova equipe será criada.', 
      }
    },
    {
      element: '#team_btn-cancel', 
      popover: {
        title: 'Cancelar', 
        description: 'Caso não queira criar uma nova equipe, basta clicar no botão Cancelar.', 
        onNextClick: () => {
          const btnCancelModalTeam: any = document.querySelector('#team_btn-cancel');
          btnCancelModalTeam.click();
          driverObj.moveNext();
        }
      }
    },
    { 
      element: '#team_list-teams', 
      popover: { 
        title: 'Minhas equipes', 
        description: 'Aqui você verá todas as suas equipes, os membros de cada equipe, status e as opções de convidar um novo membro, editar ou excluir membros e alterar o nome da equipe caso você tenha permissão.', 
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'team'})) {
            const teamModal: any = document.querySelector('#team_btn-new-team');
            teamModal?.click();
            setTimeout(() => {
              driverObj.movePrevious();
            }, 200);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(0) : driverObj.moveTo(1);
          }
        }
      } 
    },
    { 
      element: '#team_table-name', 
      popover: { 
        title: 'Nome da equipe', 
        description: 'Nesta coluna estará os nomes das suas equipes.' 
      } 
    },
    { 
      element: '#team_table-members', 
      popover: { 
        title: 'Membros da equipe', 
        description: 'Na coluna Membros estará todos os membros de cada equipe.' 
      } 
    },
    { 
      element: '#team_table-status', 
      popover: { 
        title: 'Status da equipe', 
        description: 'Nesta coluna estará o status de cada equipe.', 
        onNextClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'team'}) || hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'team'}) || hasPermission({ permissions: permissiosThisModule, action: 'invite', model_name:'team'})) {
            driverObj.moveNext();
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(31) : driverObj.moveTo(32);
          }
        }
      } 
    },
    { 
      element: '#team_table-actions', 
      popover: { 
        title: 'Menu da equipe', 
        description: 'Na coluna Ações é possível convidar um novo membro, editar informações de uma equipe e excluir a equipe ou membros.',
        onNextClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'invite', model_name:'team'})) {
            driverObj.moveNext();
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'team'})) {
            isPathnameEqualToModule ? driverObj.moveTo(18) : driverObj.moveTo(19);
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'team'})) {
            isPathnameEqualToModule ? driverObj.moveTo(29) : driverObj.moveTo(30);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(31) : driverObj.moveTo(32);
          }
        }
      } 
    },
    {
      element: '#team_table-action-invite', 
      popover: {
        title: 'Enviar convite', 
        description: 'Neste menu você poderá convidar um novo membro. Veja como é simples...', 
        onNextClick: () => {
          const btnInviteNewMember: any = document.querySelector('#team_table-action-invite');
          btnInviteNewMember.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        }
      }
    },
    {
      element: '#team_modal-invite', 
      popover: {
        title: 'Enviar convite', 
        description: 'Este é o formulário para convidar um novo membro. Veja como é simples...', 
        onPrevClick: () => {
          const btnInviteCancel: any = document.querySelector('#team_modal-btn-cancel');
          btnInviteCancel.click();
          setTimeout(() => {
            driverObj.movePrevious();
          }, 200);
        }
      }
    },
    {
      element: '#team_modal-invite-emails', 
      popover: {
        title: 'Email do convidado', 
        description: 'Após clicar na opção Convidar clique no input para inserir o email...', 
        onNextClick: () => {
          const btnInviteInsertEmail: any = document.querySelector('#team_modal-insert-email');
          btnInviteInsertEmail.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        }
      }
    },
    {
      element: '#team_modal-invite-emails',
      popover: {
        title: 'Email do convidado', 
        description: 'Coloque o email do convidado...', 
        onNextClick: () => {
          const btnCloseInsertEmail: any = document.querySelector('#team_modal-close-insert-email');
          btnCloseInsertEmail.click();
          driverObj.moveNext();
        },
        onPrevClick: () => {
          const btnCloseInsertEmail: any = document.querySelector('#team_modal-close-insert-email');
          btnCloseInsertEmail.click();
          setTimeout(() => {
            driverObj.movePrevious();
          }, 200);
        }
      }
    },
    { 
      element: '#team_modal-function', 
      popover: { 
        title: 'Escolha a função', 
        description: 'Após inserir os emails escolha a função do novos membros.',
        onPrevClick: () => {
          const btnInviteInsertEmail: any = document.querySelector('#team_modal-insert-email');
          btnInviteInsertEmail.click();
          setTimeout(() => {
            driverObj.movePrevious();
          }, 200);
        }
      } 
    },
    {
      element: '#team_modal-send-invite', 
      popover: {
        title: 'Convidar', 
        description: 'Após clicar na opção Convidar o convite será enviado pro e-mail informado', 
      }
    },
    {
      element: '#team_modal-btn-cancel', 
      popover: {
        title: 'Cancelar', 
        description: 'Caso deseje cancelar o convite, clique no botão Cancelar', 
        onNextClick: () => {
          const btnInviteCancel: any = document.querySelector('#team_modal-btn-cancel');
          btnInviteCancel.click();
          setTimeout(() => {
            if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'team'})) {
                driverObj.moveNext();
            } else if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'team'})) {
              isPathnameEqualToModule ? driverObj.moveTo(29) : driverObj.moveTo(30);
            } else {
              isPathnameEqualToModule ? driverObj.moveTo(31) : driverObj.moveTo(32);
            }
          }, 200);
        },
      }
    },
    {
      element: '#team_table-action-update', 
      popover: {
        title: 'Editar equipe', 
        description: 'Vamos aprender como editar as informações da equipe. Clique no botão <strong>Editar</strong> para acessar o formulário de edição', 
        onNextClick: () => {
          const btnModalEdit: any = document.querySelector('#team_table-action-update');
          btnModalEdit.click();

          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        },
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'invite', model_name:'team'})) {
            const btnInviteNewMember: any = document.querySelector('#team_table-action-invite');
            btnInviteNewMember.click();
            setTimeout(() => {
              driverObj.movePrevious();
            }, 200);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(9) : driverObj.moveTo(10);
          }
        }
      }
    },
    {
      element: '#team_modal-edit-team', 
      popover: {
        title: 'Formulário de edição', 
        description: 'Agora vamos ver como editar as informações da equipe', 
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'invite', model_name:'team'})) {
            const btnCloseModalEdit: any = document.querySelector('#close-team_modal-edit-team');
            btnCloseModalEdit.click();
            setTimeout(() => {
              driverObj.movePrevious();
            }, 200);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(9) : driverObj.moveTo(10);
          }
        }
      }
    },
    {
      element: '#team_modal-edit-name-team', 
      popover: {
        title: 'Alterar nome', 
        description: 'Aqui voce pode alterar o nome da equipe, clicando no botão <strong>Alterar</strong>',
        onNextClick: () => {
          const btnEditName: any = document.querySelector('#team_modal-edit-open-change-name-team');
          if(btnEditName) {
            btnEditName.click();
          }

          driverObj.moveNext();
        } 
      }
    },
    {
      element: '#team_modal-edit-name-team', 
      popover: {
        title: 'Alterar nome', 
        description: `Aqui você pode alterar inserir o novo nome no campo "Novo nome da equipe" e depois clicar no botão <strong>Salvar</strong> <br />
                    Caso não deseje alterar o nome, basta clicar no botão <strong>Cancelar</strong>
        `,
        onNextClick: () => {
          const btnEditName: any = document.querySelector('#team_modal-edit-close-change-name-team');
          btnEditName.click();

          driverObj.moveNext();
        } 
      }
    },
    {
      element: '#team_modal-edit-status', 
      popover: {
        title: 'Status da equipe', 
        description: `Aqui você pode alterar o status da equipe, clicando no campo ao lado e selecionando o novo status. <br />`,
      }
    },
    {
      element: '#table-edit-team', 
      popover: {
        title: 'Membros da equipe', 
        description: `Aqui está a lista dos membros da equipe e as opção de edição de cada membro caso você tenha permissão.`,
        onNextClick: () => {
          driverObj.moveNext();
        }
      }
    },
    {
      element: '#table-edit-team tbody tr td:nth-child(1)', 
      popover: {
        title: 'Nome do membro',
      }
    },
    {
      element: '#table-edit-team tbody tr td:nth-child(2)', 
      popover: {
        title: 'Função do membro', 
        description: `Selecione aqui a função do membro.`,
      }
    },
    {
      element: '#table-edit-team tbody tr td:nth-child(3)', 
      popover: {
        title: 'Ativar/Desativar o membro', 
        description: `Ative ou desative um membro clicando neste botão.`,
      }
    },
    {
      element: '#table-edit-team tbody tr td:nth-child(4)', 
      popover: {
        title: 'Configurar função ou excluir membro', 
        description: `No botão <strong>Configuração</strong> clique para configurar as permissões do cargo do membro. <br /> 
                      No botão <strong>Excluir</strong> clique para excluir o membro.
        `,
      }
    },
    {
      element: '#team_modal-edit-submit', 
      popover: {
        title: 'Atualizar equipe', 
        description: `Caso deseje alterar as informações da equipe, clique no botão <strong>Atualizar</strong>`,
        onNextClick: () => {
          const btnCloseModalEditTeam: any = document.querySelector('#close-team_modal-edit-team');
          btnCloseModalEditTeam.click();
          setTimeout(() => {
            if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'team'})) {
              driverObj.moveNext();
            } else {
              isPathnameEqualToModule ? driverObj.moveTo(31) : driverObj.moveTo(32);
            }
          },200);
        },
      }
    },
    {
      element: '#team_table-action-destroy', 
      popover: {
        title: 'Excluir equipe', 
        description: `Caso deseje exlcuir uma equipe, clique no botão <strong>Excluir</strong>`,
        onNextClick: () => {
          const btnOpenModalDelete: any = document.querySelector('#team_table-action-destroy');
          triggerEvent(btnOpenModalDelete, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        },
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'team'})) {
            const btnModalEdit: any = document.querySelector('#team_table-action-update');
            btnModalEdit.click();

            setTimeout(() => {
              driverObj.movePrevious();
            }, 200);
          } else if (hasPermission({ permissions: permissiosThisModule, action: 'invite', model_name:'team'})) {
            const btnInviteNewMember: any = document.querySelector('#team_table-action-invite');
            btnInviteNewMember.click();
            setTimeout(() => {
              isPathnameEqualToModule ? driverObj.moveTo(17) : driverObj.moveTo(18)
            }, 200);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(9) : driverObj.moveTo(10);
          }
        }
      }
    },
    {
      element: '.swal2-popup', 
      popover: {
        title: 'Excluir equipe', 
        description: `Neste formulário será perguntado se realmente deseja excluir a equipe. <br />
                      Caso sim, clique no botão <strong>Excluir</strong> <br />
                      Caso não, clique no botão <strong>Cancelar</strong>
        `,
        onNextClick: () => {
          const btnCloseModalDelete: any = document.querySelector('.swal2-cancel');
          btnCloseModalDelete.click();

          driverObj.moveNext();
        },
        onPrevClick: () => {
          const btnCloseModalDelete: any = document.querySelector('.swal2-cancel');
          btnCloseModalDelete.click();

          setTimeout(() => {
            driverObj.movePrevious();
          }, 200);
        }
      }
    },
    { 
      element: '#menu-tutorial', 
      popover: { 
        title: 'Tutorial encerrado, Obrigado!', 
        description: 'Você pode ver novamente este tutorial clicando no menu Tutoriais',
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'team'})) {
            const btnOpenModalDelete: any = document.querySelector('#team_table-action-destroy');
            triggerEvent(btnOpenModalDelete, 'click', true);
            setTimeout(() => {
              driverObj.movePrevious();
            }, 200);
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'team'})) {
            const btnModalEdit: any = document.querySelector('#team_table-action-update');
            btnModalEdit.click();
            setTimeout(() => {
              isPathnameEqualToModule ? driverObj.moveTo(28) : driverObj.moveTo(29);
            }, 200);
          } else if (hasPermission({ permissions: permissiosThisModule, action: 'invite', model_name:'team'})) {
            const btnInviteNewMember: any = document.querySelector('#team_table-action-invite');
            btnInviteNewMember.click();
            setTimeout(() => {
              isPathnameEqualToModule ? driverObj.moveTo(17) : driverObj.moveTo(18);
            }, 200);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(9) : driverObj.moveTo(10);
          }
        }
      } 
    },
  ];

  const StepsOccurrences: DriveStep[] = [
    {
      element: window.innerWidth <= 992 ? '.offcanvas #menu_event' : '#menu_event',
      popover: {
        title: 'Módulo de Ocorrências.',
        description: 'Vou te mostrar as funcionalidades do módulo de ocorrências. Vamos aprender?',
        onNextClick: () => {
          const btnModuleEvent: any = document.querySelector('#menu_event > a');
          btnModuleEvent.click();
          //next step
          setTimeout(() => {
            if (window.innerWidth <= 992) {
              const buttonCloseMobile: any = document.querySelector('.offcanvas .button-close');
              if (buttonCloseMobile) {
                buttonCloseMobile.click();
              }
            }
            driverObj.moveNext();
          }, 1000);
        }
      }
    },
    { element: '#event-list', popover: { title: 'Listagem das ocorrências', description: 'Aqui você verá todas as suas ocorrências' } },
    { element: '#event-search', 
      popover: { 
        title: 'Pesquisar ocorrências', 
        description: 'Você poderá digitar uma ocorrência específica e clicar na lupa para realizar a busca.',
        onNextClick: () => {
          const emptyOccurrence: any = document.querySelector('#event-list .empty-data');
          if (emptyOccurrence) {
            isPathnameEqualToModule ? driverObj.moveTo(43) : driverObj.moveTo(44);
          } else {
            driverObj.moveNext();
          }
        }
      } 
    },
    { element: '.item-event', popover: { title: 'Ocorrência', description: 'Essa é uma ocorrência que você criou, vamos entende-la...' } },
    { element: '.infos-event', popover: { title: 'Data e comentários', description: 'Aqui veremos a data de criação e os comentário da ocorrência' } },
    {
      element: '.tags-event',
      popover: {
        title: 'Tags da ocorrência',
        description: 'Tags que identificam a importância e o tipo de ocorrência.'
      }
    },
    {
      element: '.title-event',
      popover: {
        title: 'Acessando a ocorrência',
        description: 'Clique numa ocorrência para acessar e ver os detalhes da ocorrência.',
        onNextClick: () => {
          const btnModuleEventOccurrenceOne: any = document.querySelector('.title-tags-event > a');
          btnModuleEventOccurrenceOne.click();
          //next step
          setTimeout(() => {
            driverObj.moveNext();
          }, 1500);
        }
      }
    },
    {
      element: '.title',
      popover: {
        title: 'Título da ocorrência',
        description: 'Nome dado a esta ocorrência durante a criação.'
      }
    },
    {
      element: '.tags-list',
      popover: {
        title: 'Tags da ocorrência',
        description: 'Aqui voce pode ver as tags que identificam a importância e o tipo de ocorrência e se tem comentários.'
      }
    },
    {
      element: '.info-event',
      popover: {
        title: 'Data e quem criou',
        description: 'Veja a data e hora da criação e quem criou esta ocorrência.'
      }
    },
    {
      element: '.preview-image',
      popover: {
        title: 'Anexos da ocorrência',
        description: 'Está estará os anexos da ocorrência criada.'
      }
    },
    {
      element: '.comments',
      popover: {
        title: 'Descrição da ocorrência',
        description: 'Descrição criada durante a criação da ocorrência.',
        onNextClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'event'})){ 
            driverObj.moveNext();
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'event'})) {
            isPathnameEqualToModule ? driverObj.moveTo(19) : driverObj.moveTo(20);
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:"eventComment"})) {
            isPathnameEqualToModule ? driverObj.moveTo(23) : driverObj.moveTo(24);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(42) : driverObj.moveTo(43);
          }
        }
      }
    },
    // Edição da ocorrência
    {
      element: '.edit-button',
      popover: {
        title: 'Editar ocorrência',
        description: 'Para editar a ocorrência clique aqui.',
        onNextClick: () => {
          const btnModuleEventEdit: any = document.querySelector('.edit-button');
          btnModuleEventEdit.click();
          //next step
          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        }
      }
    },
    {
      element: '#wrapper-comment-group',
      popover: {
        title: 'Editar ocorrência',
        description: 'Aqui você poderá alterar o nome da ocorrência.'
      }
    },
    {
      element: '#files-group',
      popover: {
        title: 'Alterar arquivos da ocorrência',
        description: 'Aqui você altera o arquivo da ocorrência.'
      }
    },
    {
      element: '#importance-group',
      popover: {
        title: 'Importância de importância da ocorrência',
        description: 'Aqui você altera o tipo de importância da ocorrência.'
      }
    },
    {
      element: '#event-group',
      popover: {
        title: 'Tipo da ocorrência',
        description: 'Aqui você altera o tipo da ocorrência.'
      }
    },
    {
      element: '#description-group',
      popover: {
        title: 'Alterar a descrição da ocorrência',
        description: 'Aqui você realizar a alteração da descrição da ocorrência.'
      }
    },
    {
      element: '.button-primary',
      popover: {
        title: 'Concluir edição da ocorrência',
        description: 'Se deseja realizar alterações clique aqui.'
      }
    },
    {
      element: '.button-cancel',
      popover: {
        title: 'Cancelar edição',
        description: 'Se não deseja realizar alterações clique aqui.',
        onNextClick: () => {
          const btnModuleEventCancel: any = document.querySelector('.button-cancel');
          btnModuleEventCancel.click();
          //next step
          setTimeout(() => {
            if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'occurrence'})) {
              driverObj.moveNext();
            } else if(hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'eventComment'})) {
              isPathnameEqualToModule ? driverObj.moveTo(23) : driverObj.moveTo(24);
            } else {
              isPathnameEqualToModule ? driverObj.moveTo(42) : driverObj.moveTo(43);
            }
          }, 500);
        }
      }
    },
    //Excluir ocorrência
    {
      element: '#delete-button-event',
      popover: {
        title: 'Excluir ocorrência',
        description: 'Para excluir a ocorrência clique neste botão.',
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'event'})) {
            const btnModuleEventEdit: any = document.querySelector('.edit-button');
            btnModuleEventEdit.click();
            //next step
            setTimeout(() => {
              driverObj.movePrevious();
            }, 500);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(10) : driverObj.moveTo(11);
          }
        },
        onNextClick: () => {
          const btnModuleEventDelete: any = document.querySelector('#delete-button-event');
          btnModuleEventDelete.click();
          //next step
          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        },
      }
    },
    {
      element: '#modal-delete-event',
      popover: {
        title: 'Excluir ocorrência',
        description: 'Aqui você pode excluir esta ocorrência, basta clicar em confirmar.'
      }
    },
    {
      element: '#button-primary-modal-delete',
      popover: {
        title: 'Confirmar exclusão',
        description: 'Se deseja excluir a ocorrência clique aqui.'
      }
    },
    {
      element: '#button-cancel-modal-delete',
      popover: {
        title: 'Cancelar exclusão',
        description: 'Se não deseja excluir a ocorrência clique em cancelar.',
        onNextClick: () => {
          const btnModuleEventModalDeleteCancel: any = document.querySelector('#button-cancel-modal-delete');
          btnModuleEventModalDeleteCancel.click();
          setTimeout(() => {
            if(hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'eventComment'})) {
              driverObj.moveNext();
            } else {
              isPathnameEqualToModule ? driverObj.moveTo(42) : driverObj.moveTo(43);
            }
          }, 500);
        }
      }
    },
    // List comentários
    {
      element: '.list-comments',
      popover: {
        title: 'Comentários',
        description: 'Aqui estará os comentários desta ocorrência.',
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'event'})) {
            const btnModuleEventDelete: any = document.querySelector('#delete-button-event');
            btnModuleEventDelete.click();
            //next step
            setTimeout(() => {
              driverObj.movePrevious();
            }, 200);
          } else if (hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'event'})) {
            const btnModuleEventEdit: any = document.querySelector('.edit-button');
            btnModuleEventEdit.click();
            //next step
            setTimeout(() => {
              isPathnameEqualToModule ? driverObj.moveTo(18) : driverObj.moveTo(19);
            }, 500);

          } else {
            isPathnameEqualToModule ? driverObj.moveTo(10) : driverObj.moveTo(11);
          }
        }
      }
    },
    {
      element: '.info-comment',
      popover: {
        title: 'Quem criou e data do comentário',
        description: 'Veja quem criou o comentário, data e hora da criação.'
      }
    },
    {
      element: '.content > .comment',
      popover: {
        title: 'Comentário',
        description: 'Aqui é o comentário criado.'
      }
    },
    {
      element: '.files',
      popover: {
        title: 'Anexos do comentário',
        description: 'Este é o anexo criado no comentário.',
        onNextClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'eventComment'})) {
            driverObj.moveNext();
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'eventComment'}) || hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'eventComment'})) {
            isPathnameEqualToModule ? driverObj.moveTo(31) : driverObj.moveTo(32);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(42) : driverObj.moveTo(43);
          }
        }
      }
    },
    // Criar comentário
    {
      element: '#form-envio-comments',
      popover: {
        title: 'Criar comentário',
        description: 'Aqui temos a área para realizar comentários.'
      }
    },
    {
      element: '#file',
      popover: {
        title: 'Anexar arquivos',
        description: 'Ao clicar neste botão você poderá adicionar um anexo ao comentário.'
      }
    },
    {
      element: '#input-send-comment',
      popover: {
        title: 'Digitar comentário',
        description: 'Basta digitar seu comentário neste campo.'
      }
    },
    {
      element: 'button[type="submit"]',
      popover: {
        title: 'Enviar comentário',
        description: 'Agora é só clicar em enviar o comentário.',
        onNextClick: () => {
          //27 - comentários
          if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'eventComment'}) || hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'eventComment'})) {
            driverObj.moveNext();
          }  else {
            isPathnameEqualToModule ? driverObj.moveTo(42) : driverObj.moveTo(43);
          }
        }
      }
    },
    // Menu de opções
    {
      element: '.dropdown-actions',
      popover: {
        title: 'Menu de opções',
        description: 'Clique aqui para abrir as opções.',
        onNextClick: () => {
          //27 - comentários
          const btnModuleEventModalEditComment: any = document.querySelector('.dropdown-actions');
          btnModuleEventModalEditComment.click();
          setTimeout(() => {
            if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'eventComment'})) {
              driverObj.moveNext();
            } else if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'eventComment'})) {
              isPathnameEqualToModule ? driverObj.moveTo(37) : driverObj.moveTo(38);
            } else {
              btnModuleEventModalEditComment.click();
              isPathnameEqualToModule ? driverObj.moveTo(42) : driverObj.moveTo(43);
            }
          }, 500);
        },
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'eventComment'})) {
            const btnModuleEventModalEditComment: any = document.querySelector('.dropdown-actions');
            btnModuleEventModalEditComment.click();
            isPathnameEqualToModule ? driverObj.moveTo(30) : driverObj.moveTo(31);
          } else {
            const btnModuleEventModalEditComment: any = document.querySelector('.dropdown-actions');
            btnModuleEventModalEditComment.click();
            isPathnameEqualToModule ? driverObj.moveTo(26) : driverObj.moveTo(27);
          }
        }
      }
    },
    // Edição
    {
      element: '.dropdown-actions-edit',
      popover: {
        title: 'Botão de edição',
        description: 'Clicando aqui você poderá editar informações do comentário.',
        onNextClick: () => {
          const btnModuleEventModalEditComment: any = document.querySelector('.dropdown-actions-edit');
          btnModuleEventModalEditComment.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        }
      }
    },
    {
      element: '#comment-group',
      popover: {
        title: 'Alterar comentário',
        description: 'Neste campo altere o comentário.',
        onPrevClick: () => {
          const btnModuleEventModalEditCancel: any = document.querySelector('.button-cancel');
          btnModuleEventModalEditCancel.click();
          const btnModuleEventModalEditComment: any = document.querySelector('.dropdown-actions');
          btnModuleEventModalEditComment.click();
          setTimeout(() => {
            driverObj.movePrevious();
          }, 500);
        }
      }
    },
    {
      element: '#files-group',
      popover: {
        title: 'Alterar anexo',
        description: 'Neste campo você pode anexar o novo arquivo. Obs.: O anexo anterior será substituído.',
      }
    },
    {
      element: '.button-primary',
      popover: {
        title: 'Confirmar edição',
        description: 'Clique em confirmar para salvar as alterações.',
      }
    },
    {
      element: '.button-cancel',
      popover: {
        title: 'Cancelar edição',
        description: 'Clique em cancelar para cancelar as alterações.',
        onNextClick: () => {
          const btnModuleEventModalEditComment: any = document.querySelector('.button-cancel');
          btnModuleEventModalEditComment.click();
          setTimeout(() => {
            if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'eventComment'})) {
              driverObj.moveNext();
            } else {
              isPathnameEqualToModule ? driverObj.moveTo(42) : driverObj.moveTo(43);
            }
          }, 500);
        }
      }
    },
    // Excluir
    {
      element: '.dropdown-actions',
      popover: {
        title: 'Menu de opções',
        description: 'Clique novamente aqui para abrir o menu de opções.',
        onNextClick: () => {
          const btnModuleEventModalEditComment: any = document.querySelector('.dropdown-actions');
          btnModuleEventModalEditComment.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        }, 
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'eventComment'})) {
            const btnModuleEventModalEditComment: any = document.querySelector('.dropdown-actions-edit');
            btnModuleEventModalEditComment.click();
            setTimeout(() => {
              driverObj.movePrevious();
            }, 200);
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'eventComment'})) {
            const btnModuleEventModalEditComment: any = document.querySelector('.dropdown-actions');
            btnModuleEventModalEditComment.click();
            isPathnameEqualToModule ? driverObj.moveTo(30) : driverObj.moveTo(31);
          } else {
            const btnModuleEventModalEditComment: any = document.querySelector('.dropdown-actions');
            btnModuleEventModalEditComment.click();
            isPathnameEqualToModule ? driverObj.moveTo(26) : driverObj.moveTo(27);
          }
        }
      }
    },
    {
      element: '.button-delete',
      popover: {
        title: 'Botão excluir',
        description: 'Clicando aqui você irá excluir o comentário.',
        onNextClick: () => {
          const btnModuleEventModalDeleteComment: any = document.querySelector('.button-delete');
          btnModuleEventModalDeleteComment.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        },
        onPrevClick: () => {
          driverObj.movePrevious();
        }
      }
    },
    {
      element: '.modal-delete-comment',
      popover: {
        title: 'Excluir comentário',
        description: 'Modal de confirmação para excluir o comentário.',
        onPrevClick: () => {
          const btnModuleEventCloseModalComment: any = document.querySelector('.button-cancel');
          btnModuleEventCloseModalComment.click();
          const btnModuleEventModalEditComment: any = document.querySelector('.dropdown-actions');
          btnModuleEventModalEditComment.click();
          setTimeout(() => {
            driverObj.movePrevious();
          }, 500);
        }
      },
    },
    {
      element: '.button-primary',
      popover: {
        title: 'Excluir comentário',
        description: 'Se desejar excluir o comentário, clique no botão confirmar.'
      }
    },
    {
      element: '.button-cancel',
      popover: {
        title: 'Cancelar a exclusão do comentário',
        description: 'Caso não desejar excluir o comentário, clique no botão cancelar.',
        onNextClick: () => {
          const btnModuleEventCloseModalComment: any = document.querySelector('.button-cancel');
          btnModuleEventCloseModalComment.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        }
      }
    },
    { 
      element: '#menu-tutorial', 
      popover: { 
        title: 'Tutorial encerrado, Obrigado!', 
        description: 'Você pode ver novamente este tutorial clicando no menu Tutoriais',
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'list', model_name:'eventComment'})) {
            if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'eventComment'})) {
              const btnModuleEventModalDeleteComment: any = document.querySelector('.button-delete');
              btnModuleEventModalDeleteComment.click();
              setTimeout(() => {
                driverObj.movePrevious();
              }, 500);
            } else if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'eventComment'})) {
              const btnModuleEventModalEditComment: any = document.querySelector('.dropdown-actions-edit');
              btnModuleEventModalEditComment.click();
              setTimeout(() => {
                isPathnameEqualToModule ? driverObj.moveTo(36) : driverObj.moveTo(37);
              }, 200);
            } else if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'eventComment'})) {
              isPathnameEqualToModule ? driverObj.moveTo(30) : driverObj.moveTo(31);
            } else {
              isPathnameEqualToModule ? driverObj.moveTo(26) : driverObj.moveTo(27);
            }
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'event'})) {
            const btnModuleEventDelete: any = document.querySelector('#delete-button-event');
            btnModuleEventDelete.click();
            //next step
            setTimeout(() => {
              isPathnameEqualToModule ? driverObj.moveTo(22) : driverObj.moveTo(23);
            }, 200);
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'event'})) {
            const btnModuleEventEdit: any = document.querySelector('.edit-button');
            btnModuleEventEdit.click();
            //next step
            setTimeout(() => {
              isPathnameEqualToModule ? driverObj.moveTo(18) : driverObj.moveTo(19);
            }, 500);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(10) : driverObj.moveTo(11);
          }
        },
        onNextClick: () => {
          driverObj.destroy();
        }
      } 
    },
    {
      element: '#menu-tutorial',
      popover: {
        title: 'Nenhum item cadastrado',
        description: 'Nenhuma ocorrência cadastrada. Por favor, visite o tutorial do mapa da propriedade para aprender como criar uma.',
        onPrevClick: () => {
          driverObj.destroy();
        }
      }
    }
  ]

  const StepsKpi: DriveStep[] = [
    {
      element: window.innerWidth <= 992 ? '.offcanvas #menu_kpi' : '#menu_kpi',
      popover: {
        title: 'Bem vindo ao Aglut.',
        description: 'Vou te mostrar como criar os seus Indicadores de performance. Vamos aprender?',
        onNextClick: () => {
          const btnModuleKpi: any = document.querySelector('#menu_kpi > a');
          btnModuleKpi.click();

          //next step
          setTimeout(() => {
            if (window.innerWidth <= 992) {
              const buttonCloseMobile: any = document.querySelector('.offcanvas .button-close');
              if (buttonCloseMobile) {
                buttonCloseMobile.click();
              }
            }

            driverObj.moveNext();
          }, 1000);
        }
      },
    },
    {
      element: '#menu_center',
      popover: {
        title: 'Indicadores de Performance.',
        description: 'Bem vindo aos indicadores de performance.  Aqui você irá criar e visualizar suas métricas e assim avaliar se está no caminho certo. Vamos começar ?',
        onNextClick: () => {
          const reqKisRequests = checkRequestComplete.getRequest({nameRequest: ['kpiRequest', 'kpiCategoriesRequest']});
          const hasRequestNull = reqKisRequests.filter(req => req === null);
          const thisStep = driverObj.getActiveIndex()
          let controlFlow = 2;

          setTimeout(() => {
            if(hasRequestNull.length === 0) {
              
              if(thisStep === 1) {
                controlFlow = 3;
              }

              driverObj.moveTo(controlFlow);
              return;
            }
            
            driverObj.moveNext();
          }, 1000);
        }
      }
    },
    {
      element: '#alert-home',
      popover: {
        title: 'Atenção',
        description: 'Para uma melhor experiencia aguarde o carregamento completo da página',
        disableButtons: ['next'],
      }
    },
    {
      element: '.container-categories',
      popover: {
        title: 'Listagem dos indicadores',
        description: 'Nesta parte da página você irá visualizar a listagem dos seus indicadores. Para cada indicador, existe uma categoria vinculada a ela.',
        onPrevClick: () => {
          const thisStep = driverObj.getActiveIndex()
          let controlFlow = 0;

          if(thisStep === 3) {
            controlFlow = 1;
          }

          driverObj.moveTo(controlFlow);
        }
      }
    },
    {
      //cspell:disable-next-line
      element: '#indicador_padroes_sistema',
      popover: {
        title: "Indicadores padrões do sistema",
        description: "Nessa categoria estará presente os indicadores padrões do sistema, contendo métricas importantes relacionadas as demais páginas do sistema. Como: Equipes, Ocorrências e muito mais.",
      }
    },
    {
      element: '#indicador_equipe',
      popover: {
        title: "Visualizando um indicador",
        description: "Para visualizar as informações detalhadas de um indicador, clique em cima dele. Em sua página interna, você poderá visualizar métricas, gráficos e muito mais. Além disso, caso não seja um padrão do sistema, voce terá a possibilidade de editar ou excluir seu indicador.",
      }
    },
    //6
    {
      element: '.input-search',
      popover: {
        title: "Procurando um indicador",
        description: "Neste campo você poderá procurar pelos seus indicadores cadastrados apenas digitando o nome do seu indicador.",
        onNextClick: ()=>{
          if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'kpi'})) {
            driverObj.moveNext()
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(14) : driverObj.moveTo(15);
          }
        }
      }
    },
    {
      element: '.button-add-indicador',
      popover: {
        title: "Criando um indicador",
        description: "Para criar um indicador, basta clicar neste botão. Ao fazer isso, uma nova visualização irá aparecer na sua tela.",
        onNextClick: () => {
          const btnAddIndicador: any = document.querySelector('.button-add-indicador');
          btnAddIndicador.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 250);
        },
      }
    },
    {
      element: '#modal-add-indicador',
      popover: {
        title: "Formulário de criação de indicadores",
        description: "Este é o formulário de criação de indicadores, a parti de agora, iremos lhe mostrar o que é cada campo.",
        onNextClick: () => {
          const fieldIndicador: any = document.querySelector('#name-indicador');

          fieldIndicador.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          }, 250);
        },
        onPrevClick: () => {
          const btnClose: any = document.querySelector('.btn-close');
          btnClose.click();
          setTimeout(() => {
            driverObj.movePrevious();
          }, 250);
        }
      }
    },
    {
      element: '#name-indicador',
      popover: {
        title: "Nome do indicador",
        description: "Escolha um nome adequado ao seu indicador, que represente melhor a sua métrica",
        onNextClick: () => {
          const fieldIndicador: any = document.querySelector('#description-indicador');

          fieldIndicador.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          }, 250);
        },
      }
    },
    {
      element: '#description-indicador',
      popover: {
        title: "Descrição do indicador",
        description: "Descrava seu indicador de forma que no futuro você consiga ainda compreender o porque ele foi feito.",
        onNextClick: () => {
          const fieldIndicador: any = document.querySelector('#category-indicador');

          fieldIndicador.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          }, 250);
        },
        onPrevClick: () => {
          const fieldIndicador: any = document.querySelector('#name-indicador');

          fieldIndicador.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          setTimeout(() => {
            driverObj.movePrevious();
          }, 250);
        }
      }
    },
    {
      element: '#category-indicador',
      popover: {
        title: "Categoria do indicador",
        description: "Escolha a categoria do seu indicador. Caso escolha a categoria de modelo, o valor será habilitado para tipo tabela automaticamente.",
        onNextClick: () => {
          const fieldIndicador: any = document.querySelector('#taxonomia-indicador');

          fieldIndicador.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          }, 250);
        },
        onPrevClick: () => {
          const fieldIndicador: any = document.querySelector('#description-indicador');

          fieldIndicador.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          setTimeout(() => {
            driverObj.movePrevious();
          }, 250);
        }
      }
    },
    {
      element: '#taxonomia-indicador',
      popover: {
        title: "Taxonomia do indicador",
        description: "Escolha a qual taxonomia seu indicador representará: Amplo, Simplista, Associativo ou Teórico. Na taxonomia Simplista, o indicador será composto por um único valor, seja ela, um valor adicionado por você ou um valor encontrado em alguma dos modelos de tabelas. Já os demais, são formados por um conjunto de valor, indicador, marcador ou valor encontrado em algumas das tabelas, além disso, o usuário pode escolher qual a formula matemática que irá compor esse indicador (adição, subtração, multiplicação, divisão).",
        onNextClick: () => {
          const fieldIndicador: any = document.querySelector('#values-indicador');

          fieldIndicador.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          }, 250);
        },
        onPrevClick: () => {
          const fieldIndicador: any = document.querySelector('#category-indicador');

          fieldIndicador.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          setTimeout(() => {
            driverObj.movePrevious();
          }, 250);
        }
      }
    },
    {
      element: '#values-indicador',
      popover: {
        title: "Valores do indicador",
        description: "Dependendo da escolha da categoria e da taxonomia do seu indicador, você poderá habilitar qual o tipo do valor que você deseja atribuir ao seu indicador. Podendo ser: Um valor, um indicador, um marcador ou um valor encontrado em alguma das tabelas.",
        onNextClick: () => {
          const fieldIndicador: any = document.querySelector('.btn-submit');

          fieldIndicador.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          }, 250);
        },
        onPrevClick: () => {
          const fieldIndicador: any = document.querySelector('#taxonomia-indicador');

          fieldIndicador.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          setTimeout(() => {
            driverObj.movePrevious();
          }, 250);
        }
      }
    },
    {
      element: '.btn-submit',
      popover: {
        title: "Finalizar cadastro",
        description: "Para finalizar o cadastro do seu indicador, clique neste botão.",
      }
    },
    {
      element: '.btn-close',
      popover: {
        title: "Cancelar cadastro",
        description: "Para cancelar o cadastro do seu indicador, clique neste botão ou em qualquer lugar da área escurecida.",
        onNextClick: () => {
          const btnClose: any = document.querySelector('.btn-close');
          btnClose.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 250);
        }
      }
    },
    {
      element: '#menu-center',
      popover: {
        title: "Finalizando tutorial indicadores",
        description: "Chegamos ao final do tutorial de indicadores. Qualquer duvida, sinta-se a vontade para perguntar ao nosso suporte. Não deixe de visualizar os demais tutoriais presentes no sistema. Até a proxima!",
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'kpi'})) {
            const btnAddIndicador: any = document.querySelector('.button-add-indicador');
            btnAddIndicador.click();
            setTimeout(() => {
              driverObj.moveNext();
            }, 250);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(4) : driverObj.moveTo(5);
          }
        }
      }
    },
  ]

  const StepsOkrs: DriveStep[] = [
    {
      element: window.innerWidth <= 992 ? '.offcanvas #menu_okr' : '#menu_okr',
      popover: {
        title: 'Bem vindo ao Aglut.',
        description: 'Vou te mostrar como criar os seus OKRs. Vamos aprender?',
        onNextClick: () => {
          const btnModuleOkrs: any = document.querySelector('#menu_okr > a');
          btnModuleOkrs.click();

          setTimeout(() => {

            if (window.innerWidth <= 992) {
              const buttonCloseMobile: any = document.querySelector('.offcanvas .button-close');
              if (buttonCloseMobile) {
                buttonCloseMobile.click();
              }
            }

            driverObj.moveNext();
          }, 500);
        }
      }
    },
    {
      element: '#menu_center',
      popover: {
        title: "Metas.",
        description: 'Aqui você encontrará suas metas. Vamos conhecer?',
        onNextClick: () => {
          const hasOkrs = document.querySelector('.section-metas_header');
          const reqOkrs = checkRequestComplete.getRequest({nameRequest: ['okrsRequest', 'okrsStatusRequest', 'okrsUsersRequest']});
          const hasRequestNull = reqOkrs.filter(req => req === null);
          const thisStep = driverObj.getActiveIndex()
          let controlFlow = 2;

          if(hasRequestNull.length === 0) {

            if (!hasOkrs) {
              isPathnameEqualToModule ? driverObj.moveTo(34) : driverObj.moveTo(35);
              return;
            }

            if(thisStep === 1) {
              controlFlow = 3;
            }

            driverObj.moveTo(controlFlow);
            return;
          }

          driverObj.moveNext();
        }
      }
    },
    {
      element: '#alert-home',
      popover: {
        title: 'Atenção',
        description: 'Para uma melhor experiencia aguarde o carregamento completo da página',
        disableButtons: ['next'],
        onNextClick: () => {
          driverObj.moveTo(1);
        }
      }
    },  
    {
      element: '.section-metas_header',
      popover: {
        title: "Métricas da meta.",
        description: 'Aqui você tem uma visão geral das suas metas.',
        onPrevClick: () => {
          const thisStep = driverObj.getActiveIndex()
          let controlFlow = 1;

          if(thisStep === 2) {
            controlFlow = 0;
          }

          driverObj.moveTo(controlFlow);
        }
      }
    },
    {
      element: '#okrs-open',
      popover: {
        title: "Metas abertas.",
        description: 'Neste card voce pode ver todas as suas metas abertas.',
      }
    },
    {
      element: '#okrs-progress',
      popover: {
        title: "Metas em progresso.",
        description: 'Neste card voce pode ver o progresso das suas metas.',
      }
    },
    //6
    {
      element: '#okrs-completed',
      popover: {
        title: "Metas concluídas.",
        description: 'Neste card voce verá as suas metas concluídas.',
        onNextClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'okr'})) {
            driverObj.moveNext();
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(14) : driverObj.moveTo(15);
          }
        }
      }
    },
    // Criação
    {
      element: '#btn-create-metas',
      popover: {
        title: "Criar nova meta.",
        description: 'Para clicar uma nova meta vamos clicar neste botão. Vamos lá...',
        onNextClick: () => {
          const btnModuleOkrs: any = document.querySelector('#btn-create-metas');
          btnModuleOkrs.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        }
      }
    },
    {
      element: '#nameInput',
      popover: {
        title: "Nome da meta",
        description: 'Neste campo, informe o título da meta.',
        onPrevClick: () => {
          const btnModuleOkrsCreate: any = document.querySelector('#close-form-modal-create-new-okr');
          btnModuleOkrsCreate.click();
          //next step
          setTimeout(() => {
            driverObj.movePrevious();
          }, 300);
        },
      }
    },
    {
      element: '#dateInput',
      popover: {
        title: "Vencimento da meta",
        description: 'Neste campo, informe a data de vencimento da meta.',
      }
    },
    {
      //cspell:disable-next-line
      element: '#responsavelSelect',
      popover: {
        title: "Responsável pela meta",
        description: 'Neste campo, informe se a meta terá um responsável. Escolha a opção "sim" e no próximo campo informe o responsável. Caso não, deixe vazio.',
        // onNextClick: () => {
        //cspell:disable-next-line
        //   const selectElement: any = document.querySelector('#responsavelSelect');
        //   const optionToSelect = selectElement.options[1];

        //   selectElement.click();

        //   optionToSelect.selected = 'true';

        //   setTimeout(() => {
        //     driverObj.moveNext();
        //   }, 500);
        // }
      }
    },
    {
      element: '#describeInput',
      popover: {
        title: 'Descrição da meta',
        description: 'Insira uma descrição para a meta.',
        onNextClick: () => {
          const btnModuleOkrs: any = document.querySelector('.add-indicadores');

          btnModuleOkrs.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        }
      }
    },
    {
      element: '.add-indicadores',
      popover: {
        title: 'Adicionando indicadores de performance',
        description: 'Pesquise o(s) indicador(es) de performance que deseja adicionar.',

      }
    },
    {
      //cspell:disable-next-line
      element: '#informationMensage',
      popover: {
        title: 'Informações importantes',
        description: 'Leia as informações importantes para criar a meta.',
      }
    },
    {
      element: '.button-primary',
      popover: {
        title: 'Cadastrar',
        description: 'Agora clique no botão cadastrar para criar a meta.',
      }
    },
    // Cancelar cadastro
    // 15
    {
      element: '.button-cancel',
      popover: {
        title: 'Cancelar cadastro',
        description: 'Caso queira cancelar, clique no botão cancelar e a tela será fechada.',
        onNextClick: () => {
          const btnModuleOkrsCloseModal: any = document.querySelector('.button-cancel');
          btnModuleOkrsCloseModal.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        },
      }
    },
    {
      element: '.metas',
      popover: {
        title: 'Listagem das metas',
        description: 'Todas as metas cadastradas serão listadas aqui.',
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'okr'})){
            const btnModuleOkrs: any = document.querySelector('#btn-create-metas');
            btnModuleOkrs.click();
            setTimeout(() => {
              driverObj.movePrevious();
            }, 200);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(4) : driverObj.moveTo(5);
          }
        }
      }
    },
    {
      element: '.title',
      popover: {
        title: 'Objetivo da meta',
        description: 'Título da meta criado durante o cadastro.',
      }
    },
    {
      //cspell:disable-next-line
      element: '.porcentage',
      popover: {
        title: 'Progresso da meta',
        description: 'Aqui podemos ver o progresso da meta e mensurar a sua evolução.',
      }
    },
    {
      element: '#info-team',
      popover: {
        title: 'Equipe',
        description: 'Aqui podemos ver os membros da equipe.',
      }
    },
    {
      element: '#info-responsible',
      popover: {
        title: 'Responsável da meta',
        description: 'Aqui podemos ver o responsável da meta.',
      }
    },
    {
      element: '#info-status',
      popover: {
        title: 'Status da meta',
        description: 'Aqui podemos ver o status da meta.',
      }
    },
    {
      element: '.metas-item',
      popover: {
        title: 'Acessar informações da meta',
        description: 'Ao clicar numa meta, as informações detalhadas serão exibidas.',
        onNextClick: () => {
          const btnModuleOkrsOpenOkr: any = document.querySelector('.card-header > button');
          btnModuleOkrsOpenOkr.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        },
      }
    },
    {
      element: '.metas-item',
      popover: {
        title: 'Detalhes da meta',
        description: 'Logo apos clicar na meta, podemos ver a descrição, prazo final e os indicadores de performance que compõem a meta.',
        onPrevClick: () => {
          const btnModuleOkrsDetailsOkr: any = document.querySelector('.card-header > button');
          btnModuleOkrsDetailsOkr.click();
          //next step
          setTimeout(() => {
            driverObj.movePrevious();
          }, 300);
        },
      }
    },
    {
      element: '#info-prazo',
      popover: {
        title: 'Prazo da meta',
        description: 'Vejo o prazo da meta definido para esta meta',
      }
    },
    {
      //cspell:disable-next-line
      element: '#item-informations-desc',
      popover: {
        title: 'Descrição da meta',
        description: 'Aqui estará a descrição desta meta.',
      }
    },
    {
      //cspell:disable-next-line
      element: '#item-informations-kpis',
      popover: {
        title: 'Indicadores de performance',
        description: 'Aqui estará todos os indicadores de performance que comprem esta meta.',
        onNextClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'okr'})){
            driverObj.moveNext();
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'okr'})){
            isPathnameEqualToModule ? driverObj.moveTo(32) : driverObj.moveTo(33);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(34) : driverObj.moveTo(35); 
          }
        }
      }
    },
    // Editar meta
    //27
    {
      element: '.button-edit',
      popover: {
        title: 'Editar meta',
        description: 'Ao clicar no botão editar, o formulário de edição será exibido.',
        onNextClick: () => {
          const btnModuleOkrsOpenEditOkr: any = document.querySelector('.button-edit');
          btnModuleOkrsOpenEditOkr.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        }
      }
    },
    {
      element: '#form-modal-edit-okr',
      popover: {
        title: 'Formulário de edição',
        description: 'Aqui estará as mesmo campos que foram exibidos no formulário de cadastro, basta realizar as alterações desejadas.',
        onNextClick: () => {
          const btnModuleOkrsEditOkrRow1: any = document.querySelector('#row-1');

          btnModuleOkrsEditOkrRow1.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        },
        onPrevClick: () => {
          const btnModuleOkrsEditBack: any = document.querySelector('#close-form-modal-edit-okr');
          btnModuleOkrsEditBack.click();
          //next step
          setTimeout(() => {
            driverObj.movePrevious();
          }, 300);
        },
      }
    },
    {
      element: '#row-1',
      popover: {
        title: 'Nome e vencimento',
        description: 'Aqui você pode alterar o nome e o prazo da meta.',
      }
    },
    {
      element: '#row-2',
      popover: {
        title: 'Retirar ou Alterar o responsável',
        description: 'Aqui você pode retirar ou alterar o responsável da meta.',
      }
    },
    {
      element: '#row-3',
      popover: {
        title: 'Descrição da meta',
        description: 'Aqui você pode retirar ou alterar o responsável da meta.',
        onNextClick: () => {
          const btnModuleOkrsEditOkrRowKpis: any = document.querySelector('#row-4-kpis');

          btnModuleOkrsEditOkrRowKpis.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        }
      }
    },
    {
      element: '#row-4-kpis',
      popover: {
        title: 'Indicadores de performance',
        description: 'Altere os valores, adicione ou remova indicators de performance da meta.',
        onNextClick: () => {
          const btnModuleOkrsEditOkr: any = document.querySelector('.button-primary');

          btnModuleOkrsEditOkr.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        }
      }
    },
    {
      element: '.button-primary',
      popover: {
        title: 'Salvar alterações',
        description: 'Feito as alterações, clique em editar para salvar as alterações.',
        onNextClick: () => {
          const btnModuleOkrsCloseEditOkr: any = document.querySelector('#close-form-modal-edit-okr');
          btnModuleOkrsCloseEditOkr.click();
          setTimeout(() => {
            if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'okr'})){
              driverObj.moveNext();
            } else {
              const btnModuleOkrsOpenOkr: any = document.querySelector('.card-header > button');
              btnModuleOkrsOpenOkr.click();
              isPathnameEqualToModule ? driverObj.moveTo(34) : driverObj.moveTo(35); 
            }
          }, 200);
        }
      }
    },
    // 34
    // Excluir meta
    {
      element: '.button-delete',
      popover: {
        title: 'Excluir meta',
        description: 'Clique aqui para excluir esta meta.',
        onNextClick: () => {
          const btnModuleOkrsDeleteOkr: any = document.querySelector('.button-delete');
          btnModuleOkrsDeleteOkr.click();
          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        },
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'okr'})) {
            const btnModuleOkrsOpenEditOkr: any = document.querySelector('.button-edit');
            btnModuleOkrsOpenEditOkr.click();
            setTimeout(() => {
              driverObj.movePrevious();
            }, 300);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(24) : driverObj.moveTo(25);
          }
        }
      }
    },
    {
      element: '#form-modal-delete-okr',
      popover: {
        title: 'Excluir meta',
        description: 'Clique em confirmar para excluir esta meta ou cancelar para voltar.',
        onNextClick: () => {
          const btnModuleOkrsCloseDeleteOkr: any = document.querySelector('#close-form-modal-delete-okr');
          const btnModuleOkrsOpenOkr: any = document.querySelector('.card-header > button');

          btnModuleOkrsOpenOkr.click();
          btnModuleOkrsCloseDeleteOkr.click();

          setTimeout(() => {
            driverObj.moveNext();
          }, 200);
        },
        onPrevClick: () => {
          const btnModuleOkrsCloseDelete: any = document.querySelector('#close-form-modal-delete-okr');
          btnModuleOkrsCloseDelete.click();

          setTimeout(() => {
            driverObj.movePrevious();
          }, 300);
        },
      }
    },
    // Final
    { 
      element: '#menu-tutorial', 
      popover: { 
        title: 'Tutorial encerrado, Obrigado!', 
        description: 'Você pode ver novamente este tutorial clicando no menu Tutoriais',
        onPrevClick: () => {
          const hasOkrs = document.querySelector('.section-metas_header');
          if(!hasOkrs) {
            isPathnameEqualToModule ? driverObj.moveTo(0) : driverObj.moveTo(1);
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'okr'})) {
            const btnModuleOkrsOpenOkr: any = document.querySelector('.card-header > button');
            btnModuleOkrsOpenOkr.click();

            const btnModuleOkrsDeleteOkr: any = document.querySelector('.button-delete');
            btnModuleOkrsDeleteOkr.click();
            
            setTimeout(() => {
              driverObj.movePrevious();
            }, 500);
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'okr'})) {
            const btnModuleOkrsOpenOkr: any = document.querySelector('.card-header > button');
            btnModuleOkrsOpenOkr.click();

            const btnModuleOkrsOpenEditOkr: any = document.querySelector('.button-edit');
            btnModuleOkrsOpenEditOkr.click();
            setTimeout(() => {
              isPathnameEqualToModule ? driverObj.moveTo(31) : driverObj.moveTo(32);
            }, 200);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(24) : driverObj.moveTo(25);
          }
        },
        onNextClick: () => {
          driverObj.destroy()
        }
      } 
    },
    // 37
    {
      element: '#skdjfnksjnfs',
      popover: {
        title: 'Nenhum item cadastrado',
        description: 'Nenhuma meta foi cadastrada. Caso tenha permissão para criar um meta, por favor, clique em um dos botões abaixo para voltar para o tutorial de criação de uma meta. Caso não, por favor, solicite ao seu gestor.',
        onNextClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'okr'})) {
            isPathnameEqualToModule ? driverObj.moveTo(5) : driverObj.moveTo(6);
          } else {
            driverObj.movePrevious()
          }
        },
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'okr'})) {
            isPathnameEqualToModule ? driverObj.moveTo(5) : driverObj.moveTo(6);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(0) : driverObj.moveTo(1);
          }
        }
      }
    }
  ]

  const StepsHome: DriveStep[] = [
    {
      element: window.innerWidth <= 992 ? '.offcanvas #menu_inicio' : '#menu_inicio',
      popover: {
        title: 'Bem vindo a tela inicial do Aglut.',
        description: 'Este é o passo a passo de usabilidade, uma visão geral do sistema. Vamos lá?',
        onNextClick: () => {
          const btnModuleHome: any = document.querySelector('#menu_inicio > a');
          btnModuleHome.click();
          checkRequestComplete.clearRequestStorage({nameRequest: ['homePriceCompare', 'homePredictionClimate', 'homeCountry']});

          setTimeout(() => {
            if (window.innerWidth <= 992) {
              const buttonCloseMobile: any = document.querySelector('.offcanvas .button-close');
              if (buttonCloseMobile) {
                buttonCloseMobile.click();
              }
            }

            driverObj.moveNext();
          }, 1000);
        }
      }
    },
    {
      element: '#menu_center',
      popover: {
        title: "Início.",
        description: 'Este é o passo a passo de usabilidade, uma visão geral do sistema. Vamos lá?',
        onNextClick: () => {
          const reqPriceCompare = checkRequestComplete.getRequest({nameRequest: ['homePriceCompare', 'homePredictionClimate', 'homeCountry']});
          const hasRequestNull = reqPriceCompare.filter(req => req === null);
          const thisStep = driverObj.getActiveIndex()
          let controlFlow = 2;

          setTimeout(() => {

            if(thisStep === 0) {
              controlFlow = 1;
            }
            
            if(hasRequestNull.length === 0) {
              controlFlow = 2;
              driverObj.moveTo(controlFlow);
              return;
            }
            
            driverObj.moveTo(controlFlow);
          }, 500);
        }
      }
    },
    {
      element: '#alert-home',
      popover: {
        title: 'Atenção',
        description: 'Para uma melhor experiencia aguarde o carregamento completo da página',
        disableButtons: ['next'],

      }
    },
    {
      element: '#price-compare-filter',
      popover: {
        title: 'Culturas e período',
        description: 'Procure a cultura que deseja ver e comparar seu preço com outras regiões em um determinado período informando a cultura e o período.',
        onPrevClick: () => {
          const thisStep = driverObj.getActiveIndex()
          let controlFlow = 0;

          if(thisStep === 3) {
            controlFlow = 1;
          }

          driverObj.moveTo(controlFlow);
        }
      }
    },
    {
      element: '#compare-prices',
      popover: {
        title: 'Comparação de preços',	
        description: `Após informar a culutra e selecionado o periodo as informações de preços serão exibidas nestes cards. <br />
          Clique no nome do município ou na setinha ao lado no topo de cada card para mudar de região.`
      }
    },
    {
      element: '#map-others-regions',
      popover: {
        title: 'Comparação de preços - Mapa',	
        description: `Veja no mapa o preço da cultura em cada região. Clique em um dos pontos que aparecerá no mapa para ver os preços de cada município. <br />
            Caso deseje ver os preços em todo território nacional ou na sua região, clique nos botões que se encontrão na parte superior direito do mapa.`
      }
    },
    {
      element: '#button-price-nation',
      popover: {
        title: 'Comparação de preços - Nacional',	
        description: `Veja os preços em todo território nacional`,
        onNextClick: () => {
          const btnPriceNation: any = document.querySelector('#button-price-nation');
          btnPriceNation.click();

          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        }
      }
    },
    {
      element: '#map-others-regions',
      popover: {
        title: 'Comparação de preços - Nacional',	
        description: `O mapa focará em todo território nacional`
      }
    },
    {
      element: '#button-price-region',
      popover: {
        title: 'Comparação de preços - Regional',	
        description: `Veja os preços na sua região`,
        onNextClick: () => {
          const btnPriceRegion: any = document.querySelector('#button-price-region');
          btnPriceRegion.click();

          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        }
      }
    },
    {
      element: '#map-others-regions',
      popover: {
        title: 'Comparação de preços - Regional',	
        description: `O mapa focará na sua região`
      }
    },
    {
      element: '#map-others-regions-subtitle',
      popover: {
        title: 'Comparação de preços - Legenda',	
        description: `Na legenda terá informações sobre alguns itens no mapa`
      }
    },
    {
      element: '#wrapper-header-graph-compare',
      popover: {
        title: 'Comparação de preços culturas por municípios',	
        description: `Aqui você pode comparar os preços de duas ou mais culturas por municípios filtrando por periodo e adicionando os municípios que deseja ver no gráfico.`,
      }
    },
    {
      element: '#wrapper-compare-prices-graphics',
      popover: {
        title: 'Comparação de preços culturas por municípios',	
        description: `Após selecionado o periodo e adicionado os municípios as informações de preços são exibidas neste gráfico.`,
      }
    },
    {
      element: '#climate-today-wrapper',
      popover: {
        title: 'Acompanhe o clima do dia',	
        description: `Veja informações como: temperatura, umidade, vento e chuva. <br />
          Ao lado direito um gráfico dos últimos dias. Na parte inferior do cardo de clima é exibido a fonte dos dados.`,
      }
    },
    {
      element: '#title-filter-wrapper',
      popover: {
        title: 'Predições climáticas',	
        description: `Veja as previsões climáticas escolhendo o município e a data.
           Em seguida, as previsões serão exibidas na tabela abaixo.`,
      }
    },
    {
      element: '#weather-predictions-wrapper .prediction-climate-wrapper-Manhã',
      popover: {
        title: 'Previsões climáticas - Manhã',	
        description: `Neste gráfico serão exibidas as previsões climáticas do município e data informado.`,
      }
    },
    {
      element: '#weather-predictions-wrapper .prediction-climate-wrapper-Tarde',
      popover: {
        title: 'Previsões climáticas - Tarde',	
        description: `Neste gráfico serão exibidas as previsões climáticas do município e data informado.`,
      }
    },
    {
      element: '#weather-predictions-wrapper .prediction-climate-wrapper-Noite',
      popover: {
        title: 'Previsões climáticas - Noite',	
        description: `Neste gráfico serão exibidas as previsões climáticas do município e data informado.`,
      }
    },
    {
      element: '#menu-center',
      popover: {
        title: "Finalizando tutorial Tela inicial",
        description: "Chegamos ao final do tutorial da tela inicial. Qualquer duvida, sinta-se a vontade para perguntar ao nosso suporte. Não deixe de visualizar os demais tutoriais presentes no sistema. Até a proxima!",
      }
    },
  ]

  // Steps mapa da propriedade
  const StepsMap: DriveStep[] = [
    {
      element: window.innerWidth <= 992 ? '.offcanvas #menu_sector' : '#menu_sector',
      popover: {
        title: 'Bem vindo ao Aglut.',
        description: 'Vou te mostrar como nossa mapa da propriedade funciona. Vamos aprender?',
        onNextClick: () => {
          const btnModuleSector: any = document.querySelector('#menu_sector > a');
          btnModuleSector.click();

          setTimeout(() => {
            if (window.innerWidth <= 992) {
              const buttonCloseMobile: any = document.querySelector('.offcanvas .button-close');
              if (buttonCloseMobile) {
                buttonCloseMobile.click();
              }
            }
            driverObj.moveNext();
          }, 500);
        }
      }
    },
    {
      element: '#menu_center',
      popover: {
        title: "Mapa da propriedade.",
        description: 'Bem vindo ao mapa da propriedade, aqui você terá acesso a uma gama de funcionalidades diferentes para o seu segmento. Vamos começar ?',
      }
    },
    {
      element: '#button-show-tutorial',
      popover: {
        title: "Acessando o tutorial do mapa.",
        description: 'Ao clicar neste botão, você abrirá o tutorial do mapa separado por cada funcionalidade.',
        onNextClick: () => {
          const buttonTutorial: any = document.querySelector('#button-show-tutorial');

          if (buttonTutorial) {
            buttonTutorial.click()
          }

          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    {
      element: '#map-tutorial',
      popover: {
        title: "Tutorial do mapa.",
        description: 'Aqui está todas as funcionalidades presentes no mapa. Em cada uma delas apresentamos sua explicação de forma didática e visual. Sinta-se a vontade para explorar! Qualquer duvida, não deixe de falar com o nosso suporte.',
      }
    },
  ]

  // Steps primeiro acesso
  const StepsFirstTime: DriveStep[] = window.innerWidth < 992 ? [
    {
      element: '#menu_center',
      popover: {
        title: 'Olá, bem vindo ao Aglut.',
        description: `Primeira vez acessando o nosso sistema ? 
            Deixe-nos te mostrar um guia rápido da nossa interface. 
            Caso não seja novo por aqui e apenas está acessando nosso sistema 
            por um novo navegador, clique no botão para fechar esse tutorial no 
            canto superior direito.`,
        onCloseClick: () => {
          setCookie('newUserSystem', 'false', { expires: 365 });
          driverObj.destroy();
        }
      }
    },
    {
      element: '#header-content',
      popover: {
        title: 'Header.',
        description: `Esse é o header do sistema, aqui você terá acesso a algumas 
          funcionalidades como: Abrir/Fechar o menu lateral, Acessar as funcionalidades
          do usuário e Acessar nossos outros serviços.`
      }
    },
    {
      element: '#header-content .button-show-menu-mobile',
      popover: {
        title: 'Abrindo/Fechando o menu lateral.',
        description: `Utilize este botão para esconder ou mostrar o menu lateral.`,
      }
    },
    {
      element: '#header-content #dropdown-services',
      popover: {
        title: 'Acesso rápido aos nossos demais serviços.',
        description: `Utilize este botão para ter um acesso rápido aos nosso demais serviços. Basta clicar no que você deseja acessar e você será redirecionado para ele.`,
      }
    },
    {
      element: '#header-content .dropdown-user',
      popover: {
        title: 'Funcionalidades do usuário.',
        description: `Clicando aqui você terá acesso as funcionalidades para o usuário.`,
        onNextClick: () => {
          const btnOpenUser: any = document.querySelector('#header-content #dropdown-user');
          if (btnOpenUser) {
            if (!btnOpenUser.classList.contains('show')) {
              btnOpenUser.click();
            }
          }
          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        },

        onPrevClick: () => {
          const btnOpenUser: any = document.querySelector('#header-content #dropdown-user');
          if (btnOpenUser) {
            if (btnOpenUser.classList.contains('show')) {
              btnOpenUser.click();
            }
          }
          setTimeout(() => {
            driverObj.movePrevious();
          }, 500);
        },
      }
    },
    {
      element: '#header-content #dropdown-perfil',
      popover: {
        title: 'Acessando seu perfil.',
        description: `Clicando aqui você terá acesso ao seu perfil. 
            Nele você poderá alterar suas informações, como também, redefinir sua senha.`,
        onNextClick: () => {
          if (Object.keys(infoUser).length !== 0 && infoUser.teamSelected?.is_admin) {
            driverObj.moveNext();
          } else {
            driverObj.moveTo(8);
          }
        },
      }
    },
    {
      element: '#header-content #dropdown-config',
      popover: {
        title: 'Acessando as configurações de permissões.',
        description: `Clicando aqui você terá acesso as configurações de permissões. 
            Nele você poderá alterar as permissões dos cargos da sua equipe. 
            liberando ou bloqueando o acesso de alguma funcionalidade.`,
      }
    },
    {
      element: '#header-content #button-logout',
      popover: {
        title: 'Saindo do sistema.',
        description: `Clicando aqui você sairá do sistema, precisando refazer 
            seu login novamente.`,
        onPrevClick: () => {
          if (Object.keys(infoUser).length !== 0 && infoUser.teamSelected?.is_admin) {
            driverObj.movePrevious();
          } else {
            driverObj.moveTo(6);
          }
        },
        onNextClick: () => {
          const btnOpenUser: any = document.querySelector('#header-content #dropdown-user');
          const btnOpenMenu: any = document.querySelector('#header-content .button-show-menu-mobile');
          if (btnOpenUser) {
            btnOpenUser.click();
          }
          if (btnOpenMenu) {
            btnOpenMenu.click();
          }
          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    {
      element: '.offcanvas .aside-container',
      popover: {
        title: 'Menu lateral',
        description: `Este é nosso menu lateral, aqui você poderá navegar entre
            as inúmeras páginas de nosso sistema, como também, alternar entre suas equipes.`,
        onPrevClick: () => {
          const btnOpenUser: any = document.querySelector('#header-content #dropdown-user');
          if (btnOpenUser) {
            btnOpenUser.click();
          }
          const btnCloseMenu: any = document.querySelector('.offcanvas .button-close');
          if (btnCloseMenu) {
            btnCloseMenu.click();
          }
          setTimeout(() => {
            driverObj.movePrevious();
          }, 500);
        },
      }
    },
    {
      element: '.offcanvas #select_team',
      popover: {
        title: 'Troca de equipe',
        description: `Clicando aqui você poderá trocar entre as suas equipes..`,
        onNextClick: () => {
          const btnCloseMenu: any = document.querySelector('.offcanvas .button-close');
          if (btnCloseMenu) {
            btnCloseMenu.click();
          }

          const footer = document.querySelector('#footer-content');
          footer?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    {
      element: '#footer-content',
      popover: {
        title: 'Footer',
        description: `Esse é o nosso footer, nele você encontrar informações como
            quem fez o sistema, como também um acesso rápido aos tutoriais de cada
            funcionalidade presente no sistema.`,
        onPrevClick: () => {
          const btnOpenMenu: any = document.querySelector('#header-content .button-show-menu-mobile');
          if (btnOpenMenu) {
            btnOpenMenu.click();
          }
          setTimeout(() => {
            driverObj.movePrevious();
          }, 500)
        }
      }
    },
    {
      element: '#footer-content .tutorial',
      popover: {
        title: 'Mais tutoriais',
        description: `Chegamos ao fim desta breve explicação sobre nossa interface, 
          para ter acesso a essa e outro tutoriais, clique aqui neste botão. 
          Sinta-se a vontade para navegar em nosso sistema, em caso de mais duvida, 
          por favor contate nosso suporte. Até a proxima!`,
        onCloseClick: () => {
          setCookie('newUserSystem', 'false', { expires: 365 });
        },
        onNextClick: () => {
          setCookie('newUserSystem', 'false', { expires: 365 });
          driverObj.moveNext();
        }
      }
    }
  ] : [
    {
      element: '#menu_center',
      popover: {
        title: 'Olá, bem vindo ao Aglut.',
        description: `Primeira vez acessando o nosso sistema ? 
            Deixe-nos te mostrar um guia rápido da nossa interface. 
            Caso não seja novo por aqui e apenas está acessando nosso sistema 
            por um novo navegador, clique no botão para fechar esse tutorial no 
            canto superior direito.`,
        onCloseClick: () => {
          setCookie('newUserSystem', 'false', { expires: 365 });
          driverObj.destroy();
        }
      }
    },
    {
      element: '#header-content',
      popover: {
        title: 'Header.',
        description: `Esse é o header do sistema, aqui você terá acesso a algumas 
          funcionalidades como: Abrir/Fechar o menu lateral, Acessar as funcionalidades
          do usuário, Alternar entre suas equipes e Acessar nossos outros serviços.`
      }
    },
    {
      element: '#header-content .button-hide-menu-names',
      popover: {
        title: 'Abrindo/Fechando o menu lateral.',
        description: `Utilize este botão para esconder ou mostrar o menu lateral.`,
      }
    },
    {
      element: '#header-content #dropdown-services',
      popover: {
        title: 'Acesso rápido aos nossos demais serviços.',
        description: `Utilize este botão para ter um acesso rápido aos nosso demais serviços. Basta clicar no que você deseja acessar e você será redirecionado para ele.`,
      }
    },
    {
      element: '#header-content #select_team',
      popover: {
        title: 'Alternando entre suas equipes.',
        description: `Utilize este botão para alterar entre as suas equipes.`,
      }
    },
    {
      element: '#header-content .dropdown-user',
      popover: {
        title: 'Funcionalidades do usuário.',
        description: `Clicando aqui você terá acesso as funcionalidades para o usuário.`,
        onNextClick: () => {
          const btnOpenUser: any = document.querySelector('#header-content #dropdown-user');
          if (btnOpenUser) {
            if (!btnOpenUser.classList.contains('show')) {
              btnOpenUser.click();
            }
          }
          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        },

        onPrevClick: () => {
          const btnOpenUser: any = document.querySelector('#header-content #dropdown-user');
          if (btnOpenUser) {
            if (btnOpenUser.classList.contains('show')) {
              btnOpenUser.click();
            }
          }
          setTimeout(() => {
            driverObj.movePrevious();
          }, 500);
        },
      }
    },
    {
      element: '#header-content #dropdown-perfil',
      popover: {
        title: 'Acessando seu perfil.',
        description: `Clicando aqui você terá acesso ao seu perfil. 
            Nele você poderá alterar suas informações, como também, redefinir sua senha.`,
        onNextClick: () => {
          if (Object.keys(infoUser).length !== 0 && infoUser.teamSelected?.is_admin) {
            driverObj.moveNext();
          } else {
            driverObj.moveTo(8);
          }
        },
      }
    },
    {
      element: '#header-content #dropdown-config',
      popover: {
        title: 'Acessando as configurações de permissões.',
        description: `Clicando aqui você terá acesso as configurações de permissões. 
            Nele você poderá alterar as permissões dos cargos da sua equipe. 
            liberando ou bloqueando o acesso de alguma funcionalidade.`,
      }
    },
    {
      element: '#header-content #button-logout',
      popover: {
        title: 'Saindo do sistema.',
        description: `Clicando aqui você sairá do sistema, precisando refazer 
            seu login novamente.`,
        onPrevClick: () => {
          if (Object.keys(infoUser).length !== 0 && infoUser.teamSelected?.is_admin) {
            driverObj.movePrevious();
          } else {
            driverObj.moveTo(6);
          }
        },
        onNextClick: () => {
          const btnOpenUser: any = document.querySelector('#header-content #dropdown-user');
          if (btnOpenUser) {
            btnOpenUser.click();
          }
          driverObj.moveNext();
        }
      }
    },
    {
      element: '.aside-container',
      popover: {
        title: 'Menu lateral',
        description: `Este é nosso menu lateral, aqui você poderá navegar entre
            as inúmeras páginas de nosso sistema.`,
        onPrevClick: () => {
          const btnOpenUser: any = document.querySelector('#header-content #dropdown-user');
          if (btnOpenUser) {
            btnOpenUser.click();
          }
          setTimeout(() => {
            driverObj.movePrevious();
          }, 500);
        },
        onNextClick: () => {
          const footer = document.querySelector('#footer-content');
          footer?.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    {
      element: '#footer-content',
      popover: {
        title: 'Footer',
        description: `Esse é o nosso footer, nele você encontrar informações como
            quem fez o sistema, como também um acesso rápido aos tutoriais de cada
            funcionalidade presente no sistema.`,
      }
    },
    {
      element: '#footer-content .tutorial',
      popover: {
        title: 'Mais tutoriais',
        description: `Chegamos ao fim desta breve explicação sobre nossa interface, 
          para ter acesso a essa e outro tutoriais, clique aqui neste botão. 
          Sinta-se a vontade para navegar em nosso sistema, em caso de mais duvida, 
          por favor contate nosso suporte. Até a proxima!`,
        onCloseClick: () => {
          setCookie('newUserSystem', 'false', { expires: 365 });
          driverObj.destroy();
        },
        onNextClick: () => {
          setCookie('newUserSystem', 'false', { expires: 365 });
          driverObj.moveNext();
        }
      }
    }
  ]

  const StepsMarketData: DriveStep[] = [
    {
      element: window.innerWidth <= 992 ? '.offcanvas #menu_dataMarker' : '#menu_dataMarker',
      popover: {
        title: 'Marcadores de dados.',
        description: 'Vamos aprender como criar marcadores de dados. Clique no botão para seguir com o tutorial.',
        onNextClick: () => {
          const btnModuleDataMarket: any = document.querySelector('#menu_dataMarker > a');
          btnModuleDataMarket.click();

          setTimeout(() => {
            if (window.innerWidth <= 992) {
              const buttonCloseMobile: any = document.querySelector('.offcanvas .button-close');
              if (buttonCloseMobile) {
                buttonCloseMobile.click();
              }
            }
            driverObj.moveNext();
          }, 500);
        },
      }
    },
    {
      element: '.void',
      popover: {
        title: "Marcadores de dados.",
        description: 'Aqui você poderá ver seus marcadores e criar novos, vamos começar?.',
        onNextClick: () => {
          const reqMarkersRequest = checkRequestComplete.getRequest({nameRequest: ['markersRequest']});
          const hasRequestNull = reqMarkersRequest.filter(req => req === null);

          if(hasRequestNull.length === 0) {
            const thisStep = driverObj.getActiveIndex()
            let controlFlow = 2;
            
            if(thisStep === 1) {
              controlFlow = 3;
            }

            if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'dataMarker'})){
              const scrollBtnNewMarker: any = document.querySelector('#data_market_btn_create');
              scrollBtnNewMarker.scrollIntoView({
                behavior: "smooth",
                block: "end",
              });
    
              setTimeout(() => {
                driverObj.moveTo(controlFlow);
              }, 200);
            } else {
              isPathnameEqualToModule ? driverObj.moveTo(11) : driverObj.moveTo(12);
            }
            
            return;
          }

          driverObj.moveNext();
        },
      }
    },
    //Criar 
    // 3
    {
      element: '#alert-home',
      popover: {
        title: 'Atenção',
        description: 'Para uma melhor experiencia aguarde o carregamento completo da página',
        onNextClick: () => {
          driverObj.destroy()
        },
        disableButtons: ['next'],
      }
    },
    {
      element: '#data_market_btn_create',
      popover: {
        title: 'Criar novo marcador.',
        description: 'Vamos clicar neste botão para criar um novo marcador.',
        onNextClick: () => {
          const btnModuleDataMarketBtnCreate: any = document.querySelector('#data_market_btn_create');
          btnModuleDataMarketBtnCreate.click();

          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        },
        onPrevClick: () => {
          const thisStep = driverObj.getActiveIndex()
          let controlFlow = 0;
          if(thisStep === 3) {
            controlFlow = 1;
          }
          driverObj.moveTo(controlFlow);
        }
      }
    },
    {
      element: '#data_market_modal_create',
      popover: {
        title: 'Novo marcador.',
        description: 'Aqui esta a tela de criação de um novo marcador.',
        onPrevClick: () => {
          const btnModuleDataMarketCloseModalCreate: any = document.querySelector('#close-data_market_modal_create');
          btnModuleDataMarketCloseModalCreate.click();

          setTimeout(() => {
            driverObj.movePrevious();
          }, 500);
        }
      }
    },
    {
      element: '#form_marker_row_1 div:nth-child(1)',
      popover: {
        title: 'Nome.',
        description: 'Insira o nome do novo marcador',
      }
    },
    {
      element: '#form_marker_row_1 div:nth-child(2)',
      popover: {
        title: 'Categoria.',
        description: `
          Em categorias você terá as opções: 
            <strong>Sensor</strong> | <strong>Estação</strong> | <strong>Localização</strong> | <strong>Vídeo</strong> | <strong>Estação</strong>
            <hr/>
          Vamos selecionar a opção <strong>Estação</strong> e ver os campo que vão aparecer. Vamos prosseguir...
        `,
        onNextClick: () => {
          const select: any = document.querySelector('#categoriaInput');
          const option = select.options[2];

          const event = new Event('change', {
            bubbles: true,
            cancelable: true,
          });

          select.value = option.value;
          select.dispatchEvent(event);

          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        },
      }
    },
    {
      element: '#integrationInputWrapper',
      popover: {
        title: 'Tipo de Integração.',
        description: `
          Neste campo escolha a opção de integração que deseja.: 
            <strong>Samanu</strong> | <strong>Ckan</strong> | <strong>Outro</strong>
            <hr/>
          Ao selecionar a opção <strong>Samanau</strong> será exibido um campo para escolher uma estação Samanau. <br/>
        `,
        onNextClick: () => {
          const select: any = document.querySelector('#integrationInput');
          const option = select.options[1];

          const event = new Event('change', {
            bubbles: true,
            cancelable: true,
          });

          select.value = option.value;
          select.dispatchEvent(event);

          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        },
        onPrevClick: () => {
          const select: any = document.querySelector('#categoriaInput');
          const option = select.options[0];

          const event = new Event('change', {
            bubbles: true,
            cancelable: true,
          });

          select.value = option.value;
          select.dispatchEvent(event);

          setTimeout(() => {
            driverObj.movePrevious();
          }, 500);
        },
      }
    },
    {
      element: '#codeIntegrationInputWrapper',
      popover: {
        title: 'Selecione sua Samanau.',
        description: 'Aqui você escolherá a sua estação Samanau.',
        onPrevClick: () => {
          const select: any = document.querySelector('#integrationInput');
          const option = select.options[0];

          const event = new Event('change', {
            bubbles: true,
            cancelable: true,
          });

          select.value = option.value;
          select.dispatchEvent(event);

          setTimeout(() => {
            driverObj.movePrevious();
          }, 500);
        },
        // onNextClick: () => {
        //   const scrollDescription: any = document.querySelector('#form_marker > div.row:nth-child(3)');

        //   scrollDescription.scrollIntoView({
        //     behavior: "smooth",
        //     block: "start",
        //   });

        //   setTimeout(() => {
        //     driverObj.moveNext();
        //   }, 200)
        // }
      }
    },
    {
      element: '#form_marker_row_1 div:nth-child(2)',
      popover: {
        title: 'Categoria.',
        description: `
          Em categorias você terá as opções: 
            <strong>Sensor</strong> | <strong>Estação</strong> | <strong>Localização</strong> | <strong>Vídeo</strong> | <strong>Estação</strong>
            <hr/>
          Agora vamos selecionar a opção <strong>Vídeo</strong> novos campos serão exibidos. Vamos lá...
        `,
        onNextClick: () => {
          const select: any = document.querySelector('#categoriaInput');
          const option = select.options[4];

          const event = new Event('change', {
            bubbles: true,
            cancelable: true,
          });

          select.value = option.value;
          select.dispatchEvent(event);

          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        },
        onPrevClick: () => {
          driverObj.moveTo(3);
        }
      }
    },
    {
      element: '#urlIntegrationInputWrapper',
      popover: {
        title: 'URL de integração.',
        description: 'Insira o link da URL de integração.',
        onPrevClick: () => {
          const scrollCategories: any = document.querySelector('#form_marker_row_1 div:nth-child(2)');

          scrollCategories.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });

          setTimeout(() => {
            driverObj.movePrevious();
          }, 200)
        },
        onNextClick: () => {
          const scrollDescription: any = document.querySelector('#form_marker > div.row:nth-child(3)');

          scrollDescription.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });

          setTimeout(() => {
            driverObj.moveNext();
          })
        }
      }
    },
    {
      element: '#form_marker > div.row:nth-child(3)',
      popover: {
        title: 'Descrição.',
        description: 'Insira a descrição para o novo marcador.',
      }
    },
    {
      element: '#data_market_btn_new_marker',
      popover: {
        title: 'Cadastrar.',
        description: 'Clique no botão para criar o novo marcador.',
        onNextClick: () => {
          const btnModuleDataMarketCloseModalCreate: any = document.querySelector('#close-data_market_modal_create');
          btnModuleDataMarketCloseModalCreate.click();

          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        },
        onPrevClick: () => {
          if (driverObj.getPreviousStep()?.element === "#form_marker > div.row:nth-child(3)") {
            driverObj.movePrevious();
          } else {
            driverObj.moveTo(4);
          }
        }
      }
    },
    //Listagem
    //13
    {
      element: '#data_market_table',
      popover: {
        title: 'Listagem de marcadores.',
        description: `Aqui você pode ver todos os marcadores criados. Nesta tabela contém o Nome, Categoria, descrição do marcador e as ações.`,
        onPrevClick: () => {
          if(hasPermission({ permissions: permissiosThisModule, action: 'create', model_name:'dataMarket'})) {
            const btnModuleDataMarketBtnCreate: any = document.querySelector('#data_market_btn_create');
            
            btnModuleDataMarketBtnCreate.click();
            triggerEvent(btnModuleDataMarketBtnCreate, 'click', true);
  
            //previous step
            setTimeout(() => {
              driverObj.movePrevious();
            }, 500);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(0) : driverObj.moveTo(1);
          }
        }
      }
    },
    {
      element: '#data_market_table .filter-product input',
      popover: {
        title: 'Pesquisar marcador.',
        description: 'Neste campo você pode pesquisar pelo nome do marcador.',
      }
    },
    {
      element: '.total-page-pagination select',
      popover: {
        title: 'Qtd. marcadores por página.',
        description: 'Altera a quantidade de marcadores por página escolhendo entre 5, 10, 15 ou 20 marcadores por página.',
      }
    },
    {
      element: '.total-page-pagination .paginate',
      popover: {
        title: 'Qtd de páginas.',
        description: 'Alterne entre as páginas para acessar outros marcadores.',
        onNextClick: () => {
          const btnLinkMarkers: any = document.querySelector('#btn_link_bookmark');
          const thisStep = driverObj.getActiveIndex();

          if (btnLinkMarkers) {
            driverObj.moveNext();
            return;
          }

          driverObj.moveTo(Number(thisStep) + 2);
        }
      }
    },
    {
      element: '#btn_link_bookmark',
      popover: {
        title: 'Vincular marcador no mapa',
        description: 'Ao clicar no botão, você será redirecionado pro mapa onde escolherá onde deseja vincular o marcador.',
      }
    },
    {
      element: window.innerWidth <= 992 ? '.offcanvas #menu_sector' : '#menu_sector',
      popover: {
        title: 'Mapa da propriedade',
        description: 'Vamos para o mapa da propriedade.',
        onNextClick: () => {
          const btnModuleOkrs: any = document.querySelector('#menu_sector > a');
          btnModuleOkrs.click();

          setTimeout(() => {
            if (window.innerWidth <= 992) {
              const buttonCloseMobile: any = document.querySelector('.offcanvas .button-close');
              if (buttonCloseMobile) {
                buttonCloseMobile.click();
              }
            }
            driverObj.moveNext();
          }, 500);
        }
      }
    },
    {
      element: '#blablalba',
      popover: {
        title: 'Mapa da propriedade',
        description: 'Vamos Acessar o tutorial do mapa da propriedade para aprendermos como vincular um marcador no mapa.',
      }
    },
    {
      element: '#button-show-tutorial',
      popover: {
        title: 'Botao de tutorial',
        description: 'Clique aqui para acessar o tutorial no mapa da propriedade.',
        onNextClick: () => {
          const btnAccessTutorialMapProperties: any = document.querySelector('#button-show-tutorial');
          triggerEvent(btnAccessTutorialMapProperties, 'click', true);
          
          setTimeout(() => {
            driverObj.moveNext();
          }, 200)
        }
      }
    },
    {
      element: '#map-tutorial',
      popover: {
        title: 'Tutorial do mapa',
        description: `Aqui você encontrará informações sobre as funcionalidades do mapa da propriedade. <br>
                      Vamos até o tópico <strong>Marcadores de dados</strong>`,
        onNextClick: () => {
          const btnAccessTutorialMapProperties: any = document.querySelector('.accordion');

          const stepBookmark: any = document.querySelector('.accordion-item:nth-child(11) > .accordion-header > button');
          const stepbutton: any = document.querySelector('.accordion-item:nth-child(11) > accordion-collapse .accordion-body ul.list-steps > .accordion > accordion-item:nth-child(2) > .step > h2.accordion-header > button');

          triggerEvent(btnAccessTutorialMapProperties, 'click', true);
          triggerEvent(stepBookmark, 'click', true);
          
          setTimeout(() => {
            triggerEvent(stepbutton, 'click', true);
            driverObj.moveNext();
          }, 200)
        }
      }
    },
    {
      element: '.accordion-item:nth-child(11)',
      popover: {
        title: 'Marcadores de dados',
        description: `Veja como vincular marcadores no mapa da propriedade.`,
        onNextClick: () => {
          const dataMarker_create: any = document.querySelector('#map-tutorial > div.body > div > div:nth-child(11) > div > div > ul > div > div:nth-child(2) > li > h2 > button');

          setTimeout(() => {
            triggerEvent(dataMarker_create, 'click', true);
            
            driverObj.moveNext();
          }, 1000)
        }
      }
    },
    {
      element: '#map-tutorial',
      popover: {
        title: 'Vinculando um marcador...',
        description: `Abaixo estão os passos para vincular um marcador.`,
      }
    },
    {
      element: '#menu_dataMarker',
      popover: {
        title: 'Voltando pros Marcadores de Dados',
        description: 'Vamos voltar pra tela de marcadores de dados para concluir o tutorial.',
        onNextClick: () => {
          const btnModuleDataMarket: any = document.querySelector('#menu_dataMarker > a');
          btnModuleDataMarket.click();

          setTimeout(() => {
            driverObj.moveNext();
          }, 1000);
        }
      }
    },
    {
      element: '#asdfasdf',
      popover: {
        title: 'Continuando o tutorial...',
        description: 'Vamos continuar nossa tutorial.',
        onNextClick: () => {
          const tbody : any = document.querySelector('tbody');

          if(tbody?.children[0].querySelector('td')?.textContent === 'Nenhum marcador cadastrado') {
            isPathnameEqualToModule ? driverObj.moveTo(36) : driverObj.moveTo(37);
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'dataMarker' })) {
            driverObj.moveNext();
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'dataMarker' })) {
            isPathnameEqualToModule ? driverObj.moveTo(33) : driverObj.moveTo(34);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(36) : driverObj.moveTo(37);
          }
        } 
      }
    },
    //Editar marcador
    //26
    {
      element: '#btn_edit_marker',
      popover: {
        title: 'Editar marcador.',
        description: 'Para editar as informações de uma marcador basta clicar no botão.',
        onPrevClick: () => {
          const btnLinkMarkers: any = document.querySelector('#btn_link_bookmark');
          const thisStep = driverObj.getActiveIndex();

          if (btnLinkMarkers) {
            driverObj.movePrevious();
            return;
          }

          driverObj.moveTo(Number(thisStep) - 2);
        },
        onNextClick: () => {
          const dataMarketEdit: any = document.querySelector('#btn_edit_marker');

          triggerEvent(dataMarketEdit, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        },
      }
    },
    {
      element: '#modal_edit_marker',
      popover: {
        title: 'Editar marcador.',
        description: 'Aqui você poderá realizar as alterações desejadas.',
        onPrevClick: () => {
          const closeModal: any = document.querySelector('#close-modal_edit_marker');
          triggerEvent(closeModal, 'click', true);
          setTimeout(() => {
            driverObj.movePrevious();
          }, 200);
        }
      }
    },
    {
      element: '#nameInputWrapper',
      popover: {
        title: 'Alterar Nome.',
        description: 'Muda o nome do marcador se desejar.',
      }
    },
    {
      element: '#categoriaInputWrapper',
      popover: {
        title: 'Categoria.',
        description: `
          Em categorias você terá as opções: 
            <strong>Sensor</strong> | <strong>Estação</strong> | <strong>Localização</strong> | <strong>Vídeo</strong> | <strong>Estação</strong>
            <hr/>
          Ao selecionar as opções <strong>Estação</strong> ou <strong>Vídeo</strong> novos campos serão exibidos. Vamos prosseguir...
        `,
        onNextClick: () => {
          // const select: any = document.querySelector('#categoriaInput');
          // const option = select.options[2];

          // const event = new Event('change', {
          //   bubbles: true,
          //   cancelable: true,
          // });

          // select.value = option.value;
          // select.dispatchEvent(event);

          const select: any = document.querySelector('#categoriaInput');
          const thisStep = driverObj.getActiveIndex();

          setTimeout(() => {
            if(select.value === "Vídeo") {
              driverObj.moveTo(Number(thisStep) + 3);
              return;
            }
            if(select.value === "Estação") {
              driverObj.moveNext();
              return;
            }
            driverObj.moveTo(Number(thisStep) + 4);
          }, 500)
        },
      }
    },
    {
      element: '#integrationInputWrapper',
      popover: {
        title: 'Tipo de Integração.',
        description: `
          Neste campo escolha a opção de integração que deseja.: 
            <strong>Samanu</strong> | <strong>Ckan</strong> | <strong>Outro</strong>
            <hr/>
          Ao selecionar a opção <strong>Samanau</strong> será exibido um campo para escolher uma estação Samanau. <br/>
        `,
        onPrevClick: () => {
          const scrollDescription: any = document.querySelector('#categoriaInputWrapper');

          scrollDescription.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });

          driverObj.movePrevious();
        },
        onNextClick: () => {
          const select: any = document.querySelector('#integrationInput');
          const option = select.options[1];

          const event = new Event('change', {
            bubbles: true,
            cancelable: true,
          });

          select.value = option.value;
          select.dispatchEvent(event);

          setTimeout(() => {
            driverObj.moveNext();
          }, 1000);
        },
      }
    },
    {
      element: '#codeIntegrationInputWrapper',
      popover: {
        title: 'Selecione sua Samanau.',
        description: 'Aqui você escolherá a sua estação Samanau.',
        onNextClick: () => {
          const thisStep: any = driverObj.getActiveIndex();

          setTimeout(() => {
            driverObj.moveTo(Number(thisStep) + 2);
          })
        }
      }
    },
    {
      element: '#urlIntegrationInputEditWrapper',
      popover: {
        title: 'URL de integração.',
        description: 'Insira o link da URL de integração.',
        onPrevClick: () => {
          const thisStep = driverObj.getActiveIndex();
          const select: any = document.querySelector('#categoriaInput');

          setTimeout(() => {
            driverObj.moveTo(Number(thisStep) - 3);
          }, 200)
        },
      }
    },
    {
      element: '#describeInputEditWrapper',
      popover: {
        title: 'Descrição.',
        description: 'Insira a descrição para o novo marcador.',
        onPrevClick: () => {
          const thisStep = driverObj.getActiveIndex();
          const select: any = document.querySelector('#categoriaInput');

          setTimeout(() => {
            if(select.value === "Vídeo") {
              driverObj.movePrevious();
              return;
            }
            if(select.value === "Estação") {
              driverObj.moveTo(Number(thisStep) - 2);
              return;
            }

            driverObj.moveTo(Number(thisStep) - 4);
          }, 200)
        },
        onNextClick: () => {
          const scrollDescription: any = document.querySelector('#describeInputEditWrapper');
          const select: any = document.querySelector('#categoriaInput');

          if(select.value === "Estação" || select.value === "Vídeo") {
            scrollDescription.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }

          driverObj.moveNext();
        }
      }
    },
    {
      element: '#btn_edit_marker_button',
      popover: {
        title: 'Editar marcador.',
        description: 'Para editar as informações de uma marcador basta clicar em Editar.',
        onNextClick: () => {
          const closeModal: any = document.querySelector('#close-modal_edit_marker');
          triggerEvent(closeModal, 'click', true);
          if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'dataMarker'})) {
            driverObj.moveNext();
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(36) : driverObj.moveTo(37);
          }
        },
      }
    },
    // Excluir
    //35
    {
      element: '#btn_delete_marker',
      popover: {
        title: 'Excluir marcador.',
        description: 'Para excluir um marcador clique no ícone de lixeira.',
        onNextClick: () => {
          const openModalDeleteMarker: any = document.querySelector('#btn_delete_marker');
          triggerEvent(openModalDeleteMarker, 'click', true);
          setTimeout(() => {
            driverObj.moveNext();
          }, 500);
        },
        onPrevClick: () => { // Estou aqui
          if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'dataMarker'})) {
            const dataMarketEdit: any = document.querySelector('#btn_edit_marker');
            triggerEvent(dataMarketEdit, 'click', true);
            setTimeout(() => {
              driverObj.movePrevious();
            }, 500);
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(24) : driverObj.moveTo(25);
          }
        }
      }
    },
    {
      element: '#modal_delete_marker',
      popover: {
        title: 'Confirmação de exclusão.',
        description: `Se deseja realmente excluir o marcador, clique em <strong>Excluir</strong> <br />
          Caso deseje cancelar, clique em <strong>Cancelar</strong>`,
        onNextClick: () => {
          const closeModal: any = document.querySelector('#close-modal_delete_marker');
          triggerEvent(closeModal, 'click', true);
          if(document.querySelector('#data_market_unbound') !== null) {
            driverObj.moveNext();
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(36) : driverObj.moveTo(37);
          }
        },
        onPrevClick: () => {
          const openModalDeleteMarker: any = document.querySelector('#close-modal_delete_marker');
          triggerEvent(openModalDeleteMarker, 'click', true);
          setTimeout(() => {
            driverObj.movePrevious();
          }, 200);
        }
      }
    },
    {
      element: '#data_market_unbound',
      popover: {
        title: 'Marcadores não vinculados.',
        description: 'Aqui informaremos os marcadores não vinculados',
        onPrevClick: () => {
          const openModalDeleteMarker: any = document.querySelector('#btn_delete_marker');
          triggerEvent(openModalDeleteMarker, 'click', true);
          setTimeout(() => {
            driverObj.movePrevious();
          }, 500);
        }
      }
    },
    //Final
    //38
    { 
      element: '#menu-tutorial', 
      popover: { 
        title: 'Tutorial encerrado, Obrigado!', 
        description: 'Você pode ver novamente este tutorial clicando no menu Tutoriais',
        onNextClick: () => {
          driverObj.destroy();
        },
        onPrevClick: () => {
          const tbody : any = document.querySelector('tbody');
          if(document.querySelector('#data_market_unbound') !== null) {
            driverObj.movePrevious();
          } else if(tbody?.children[0].querySelector('td')?.textContent === 'Nenhum marcador cadastrado') {
            isPathnameEqualToModule ? driverObj.moveTo(23) : driverObj.moveTo(24);
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'destroy', model_name:'dataMarker'})) {
            const openModalDeleteMarker: any = document.querySelector('#btn_delete_marker');
            triggerEvent(openModalDeleteMarker, 'click', true);
            setTimeout(() => {
              isPathnameEqualToModule ? driverObj.moveTo(34) : driverObj.moveTo(35);
            }, 500);
          } else if(hasPermission({ permissions: permissiosThisModule, action: 'update', model_name:'dataMarker'})) {
            const dataMarketEdit: any = document.querySelector('#btn_edit_marker');
            triggerEvent(dataMarketEdit, 'click', true);
            setTimeout(() => {
              isPathnameEqualToModule ? driverObj.moveTo(32) : driverObj.moveTo(33);
            }, 500)
          } else {
            isPathnameEqualToModule ? driverObj.moveTo(23) : driverObj.moveTo(24);
          }
        }
      } 
    },
  ]

  const StepsSettings: DriveStep[] = [
    {
      element: '#sdfsdfwer',
      popover: {
        title: 'Configurações',
        description: 'Vamos conhecer o menu de configuração de permissão do Aglut.',
      }
    },
    {
      element: '#dropdown-user',
      popover: {
        title: 'Menu do Perfil',
        description: 'Vamos acessar o perfil do usuário para acessar as configurações.',
        onNextClick: () => {
          const dropdownUser: any = document.querySelector('#dropdown-user');
          triggerEvent(dropdownUser, 'click', true);
          setTimeout(() => {
            driverObj.moveNext();
          }, 200)
        }
      }
    },
    {
      element: '#dropdown-config',
      popover: {
        title: 'Menu do Configurações',
        description: 'Ao clicar no menu terá a opção Configurações, vamos clicar neste menu para acessar as configurações do sistema.',
        onNextClick: () => {
          const dropdownConfig: any = document.querySelector('#dropdown-config');
          const dropdownUser: any = document.querySelector('#dropdown-user');
          
          triggerEvent(dropdownConfig, 'click', true);

          setTimeout(() => {
            triggerEvent(dropdownUser, 'click', true);
            const reqConfigutarions = checkRequestComplete.getRequest({nameRequest: ['configurationsRequest']});
            const hasRequestNull = reqConfigutarions.filter(req => req === null);
            const thisStep = driverObj.getActiveIndex()
            let controlFlow = 3;
  
            if(hasRequestNull.length === 0) {
              if(thisStep === 2) {
                controlFlow = 4;
              }
              driverObj.moveTo(controlFlow);
              return;
            }

            driverObj.moveNext();
            
          }, 1000);
        }
      }
    },
    {
      element: '#alert-home',
      popover: {
        title: 'Atenção',
        description: 'Para uma melhor experiencia aguarde o carregamento completo da página',
        disableButtons: ['next'],
        onPrevClick: () => {
          const thisStep = driverObj.getActiveIndex()
          let controlFlow = 1;

          driverObj.moveTo(controlFlow);
          // driverObj.moveTo()
        }
      }
    },
    {
      element: '#list-role',
      popover: {
        title: 'Cargos',
        description: 'Todos os cargos criados pelo usuário estarão nesta página.',
        onPrevClick: () => {
          const dropdownUser: any = document.querySelector('#dropdown-user');
          triggerEvent(dropdownUser, 'click', true);

          setTimeout(() => {
            const thisStep = driverObj.getActiveIndex()
            let controlFlow = 1;

            if(thisStep === 4) {
              controlFlow = 2;
            }

            driverObj.moveTo(controlFlow);
          }, 200);
        }
      }
    },
    {
      element: '.btn-add',
      popover: {
        title: 'Novo cargo',
        description: 'Para criar novos cargos clique no botão <strong>Novo cargo</strong>',
        onNextClick: () => {
          const btnAdd: any = document.querySelector('.btn-add');
          triggerEvent(btnAdd, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 200)
        }
      }
    },
    {
      element: '#modal-create-role',
      popover: {
        title: 'Modal - criar cargo',
        description: `Basta informar o nome do novo cargo e clicar em <strong>Criar cargo</strong>. <hr />
          Caso deseje cancelar, clique em <strong>Cancelar</strong> ou fechar a janela.
        `,
        onNextClick: () => {
          const modalCreateRole: any = document.querySelector('#close-modal-create-role');
          triggerEvent(modalCreateRole, 'click', true);

          driverObj.moveNext();
        },
        onPrevClick: () => {
          const modalCreateRole: any = document.querySelector('#close-modal-create-role');
          triggerEvent(modalCreateRole, 'click', true);

          setTimeout(() => {
            driverObj.movePrevious();
          }, 200)
        }
      }
    },
    {
      element: '#table-list-role',
      popover: {
        title: 'Lista dos cargos',
        description: `Como podemos ver, temos os cargos e os botões para editar ou excluir. <hr />
                    Vamos ver como editar uma cargo...
        `,
        onPrevClick: () => {
          const btnAdd: any = document.querySelector('.btn-add');
          triggerEvent(btnAdd, 'click', true);

          setTimeout(() => {
            driverObj.movePrevious();
          }, 200)
        },
      }
    },
    {
      element: '.btn-edit',
      popover: {
        title: 'Editar cargo',
        description: `Vamos clicar no botão <strong>Editar cargo</strong> para acessar a tela de edição.`,
        onNextClick: () => {
          const btnUpdated: any = document.querySelector('.btn-edit');
          triggerEvent(btnUpdated, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 200)
        }
      }
    },
    {
      element: '#modal-update-role',
      popover: {
        title: 'Modal - editar cargo',
        description: `Aqui vamos editar o cargo. <hr />
                      Basta informar o novo cargo ou fazer a alteração desejada e clicar em <strong>Editar cargo</strong>. <br />
                      Ou se não quiser editar, clique em <strong>Cancelar</strong> ou fechar a janela.
        `,
        onPrevClick: () => {
          const btnUpdated: any = document.querySelector('#close-modal-update-role');
          triggerEvent(btnUpdated, 'click', true);

          setTimeout(() => {
            driverObj.movePrevious();
          }, 200)
        },
        onNextClick: () => {
          const btnUpdated: any = document.querySelector('#close-modal-update-role');
          triggerEvent(btnUpdated, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 200)
        }
      }
    },
    {
      element: '.btn-delete',
      popover: {
        title: 'Excluir cargo',
        description: `Vamos ver como excluir um cargo.`,
        onNextClick: () => {
          const btnDelete: any = document.querySelector('.btn-delete');
          triggerEvent(btnDelete, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 200)
        },
        onPrevClick: () => {
          const btnEdit: any = document.querySelector('.btn-edit');
          triggerEvent(btnEdit, 'click', true);

          setTimeout(() => {
            driverObj.movePrevious();
          }, 200)
        }
      }
    },
    {
      element: '#modal-delete-role',
      popover: {
        title: 'Modal - excluir cargo',
        description: `Aqui é perguntado se realmente deseja excluir o cargo. <hr />
                      Clique em <strong>Confirmar</strong> para excluir. <br />
                      Ou se desejar cancelar a ação, clique em <strong>Cancelar</strong> ou fechar a janela.
        `,
        onNextClick: () => {
          const btnDelete: any = document.querySelector('#close-modal-delete-role');
          triggerEvent(btnDelete, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 200)
        },
        onPrevClick: () => {
          const btnDelete: any = document.querySelector('#close-modal-delete-role');
          triggerEvent(btnDelete, 'click', true);

          setTimeout(() => {
            driverObj.movePrevious();
          }, 200)
        }
      }
    },
    {
      element: '#table-list-role table tbody > tr:nth-child(1)',
      popover: {
        title: 'Permissões do cargo',
        description: `Agora iremos ver como definir as permissoes do cargo.`,
        onPrevClick: () => {
          const btnDelete: any = document.querySelector('.btn-delete');
          triggerEvent(btnDelete, 'click', true);

          setTimeout(() => {
            driverObj.movePrevious();
          }, 200)
        },
      }
    },
    {
      element: '#table-list-role table tbody > tr:nth-child(1) td:nth-child(2)',
      popover: {
        title: 'Permissões do cargo',
        description: `Pegaremos este cargo e veremos suas permissões.`,
        onNextClick: () => {
          const btnRole: any = document.querySelector('#table-list-role table tbody > tr:nth-child(1) > td:nth-child(2) > a');
          triggerEvent(btnRole, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          },1200)
        }
      }
    },
    {
      element: '#all-permissions-role-specific',
      popover: {
        title: 'Permissões do cargo',
        description: `Nesta página vamos ver todas as permissoes do cargo.`,
      }
    },
    {
      element: '#title-section-profile',
      popover: {
        title: 'Título do cargo',
        description: `O nome do cargo acessado.`,
      }
    },
    {
      element: '.box-role-permission-início',
      popover: {
        title: 'Página inicial',
        description: `Aqui vemos a página e suas permissoes. <hr />
            Para permitir o colaborador acessar basta ativar ou desativar clicando no botão. <hr />
        `,
      }
    },
    {
      element: '.box-role-permission-início .permission-dashboard',
      popover: {
        title: 'Visualização do Início',
        description: `Aqui vemos opção de visualização da página de início. <br />
            Abaixo tem uma explicação desta opção e ao lado direito o botão para <strong>ativar</strong> ou <strong>desativar</strong> a permissão.
        `,
      }
    },
    {
      element: '.box-role-permission-início .permission-dashboard > .checkbox',
      popover: {
        title: 'Ativar/Desativar Permissão',
        description: `Para ativar ou desativar as permissões basta clicar neste botão.`,
      }
    },
    {
      element: '.box-role-permission-equipe',
      popover: {
        title: 'Permissões de Equipes',
        description: `Aqui temos o módulo de Equipes e suas permissoes: <br />
          <strong>Criação</strong>, <strong>Edição</strong>, <strong>Visualização</strong>, <strong>Exclusão</strong> e <strong>Convidar</strong>.
        `,
      }
    },
    {
      element: '.box-role-permission-setor',
      popover: {
        title: 'Permissões Mapa da propriedade',
        description: `Aqui temos o módulo de Mapa da propriedade e suas permissoes: <br />
          <strong>Criação</strong>, <strong>Edição</strong>, <strong>Visualização</strong> e <strong>Exclusão</strong>.
        `,
      }
    },
    {
      element: '.box-role-permission-marcador-de-dados',
      popover: {
        title: 'Permissões Marcadores de dados',
        description: `Aqui temos o módulo de Marcadores de dados e suas permissoes: <br />
          <strong>Criação</strong>, <strong>Edição</strong>, <strong>Visualização</strong> e <strong>Exclusão</strong>.
        `,
      }
    },
    {
      element: '.box-role-permission-ocorrência',
      popover: {
        title: 'Permissões Ocorrências',
        description: `Aqui temos o módulo de Ocorrências e suas permissoes: <br />
          <strong>Criação</strong>, <strong>Edição</strong>, <strong>Visualização</strong>, <strong>Exclusão</strong> <hr />
          Comentários nas ocorrência: <br />
          <strong>Visualização de comentários</strong>, <strong>Exclusão de comentários</strong>,
          <strong>Criação de comentários</strong> e <strong>Edição de comentários</strong>.
        `,
      }
    },
    {
      element: '.box-role-permission-indicador-de-performance',
      popover: {
        title: 'Permissões Indicadores de performance',
        description: `Aqui temos o módulo de Indicadores de performance e suas permissoes: <br />
          <strong>Criação</strong>, <strong>Edição</strong>, <strong>Visualização</strong> e <strong>Exclusão</strong>.
        `,
      }
    },
    {
      element: '.box-role-permission-meta',
      popover: {
        title: 'Permissões Metas',
        description: `Aqui temos o módulo de Metas e suas permissoes: <br />
          <strong>Criação</strong>, <strong>Edição</strong>, <strong>Visualização</strong> e <strong>Exclusão</strong>.
        `,
      }
    },
    {
      element: '.box-role-permission-análise-de-dados',
      popover: {
        title: 'Permissões Análise de dados',
        description: `Aqui temos o módulo de Análise de dados e suas permissoes: <br />
          <strong>Visualização</strong>.
        `,
      }
    },
    {
      element: '.box-role-permission-relatório',
      popover: {
        title: 'Permissões Relatórios',
        description: `Aqui temos o módulo de Relatórios e suas permissoes: <br />
          <strong>Criação</strong>, <strong>Edição</strong>, <strong>Visualização</strong> e <strong>Exclusão</strong>.
        `,
      }
    },
    {
      element: '#all-permissions-role-specific > div',
      popover: {
        title: 'Voltar para os cargos',
        description: `Para voltar para os cargos, basta clicar no botão <strong>Configurações</strong>.`,
        onNextClick: () => {
          const btnConfig: any = document.querySelector('#all-permissions-role-specific > div > ul > li:nth-child(2) > a');
          triggerEvent(btnConfig, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          },500)
        },
      }
    },
    { 
      element: '#menu-tutorial', 
      popover: { 
        title: 'Tutorial encerrado, Obrigado!', 
        description: 'Você pode ver novamente este tutorial clicando no menu Tutoriais',
        onPrevClick: () => {
          const btnRole: any = document.querySelector('#table-list-role table tbody > tr:nth-child(1) > td:nth-child(2) > a');
          triggerEvent(btnRole, 'click', true);

          setTimeout(() => {
            driverObj.movePrevious();
          },1200)
        }
      } 
    },
  ]

  const StepsDataAnalytics = [
    {
      element: window.innerWidth <= 992 ? '.offcanvas #menu_dataAnalytic' : '#menu_dataAnalytic',
      popover: {
        title: 'Análise de dados',
        describer: `Aqui temos o módulo de Análise de dados e suas permissoes: <br />`,
        onNextClick: () => {
          const moduleDataAnalitycs: any = document.querySelector('#menu_dataAnalytic > a');
          triggerEvent(moduleDataAnalitycs, 'click', true);
          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    {
      element: '#void-data-analytic',
      popover: {
        title: 'Análise de dados',
        description: `Vamos conhecer as funcionalidades deste módulo, vamos lá?.`,
      }
    },
    {
      element: '.btn-search-data',
      popover: {
        title: 'Explorar',
        description: `Para analisarmos os dados, vamos clicar neste botão e buscar os dados`,
        onNextClick: () => {
          const btnSearchData: any = document.querySelector('.btn-search-data');
          triggerEvent(btnSearchData, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          },200)
        }
      }
    },
    {
      element: '#modal-explore-data-analytics',
      popover: {
        title: 'Pesquisar series de dados',
        description: `Aqui podemos pesquisar pelas séries de dados existentes.`,
        onPrevClick: () => {
          const btnCloseModal: any = document.querySelector('.btn-close');
          triggerEvent(btnCloseModal, 'click', true);

          setTimeout(() => {
            driverObj.movePrevious();
          },200)
        }
      }
    },
    {
      element: '#my-series-data',
      popover: {
        title: 'Minhas series de dados',
        description: `Nesta opção encontraremos as suas series de dados. <br />`,
        onNextClick: () => {
          const btnBuyData: any = document.querySelector('#buy-series-data > a');
          triggerEvent(btnBuyData, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 200)
        }
      }
    },
    {
      element: '#buy-series-data',
      popover: {
        title: 'Comprar series de dados',
        description: `A compra de dados estará liberada em breve. <br /> 
                    Nesta aba será possível comprar series de dados.
        `,
        onNextClick: () => {
          const btnMyData: any = document.querySelector('#my-series-data > a');
          triggerEvent(btnMyData, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 200)
        },
        onPrevClick: () => {
          const btnMyData: any = document.querySelector('#my-series-data > a');
          triggerEvent(btnMyData, 'click', true);

          setTimeout(() => {
            driverObj.movePrevious();
          }, 200)
        }
      }
    },
    {
      element: '#my-series-data',
      popover: {
        title: 'Minhas series de dados',
        description: `Agora, vamos ver como encontramos as suas series de dados. <br />`,
        onPrevClick: () => {
          const btnBuyData: any = document.querySelector('#buy-series-data > a');
          triggerEvent(btnBuyData, 'click', true);

          setTimeout(() => {
            driverObj.movePrevious();
          }, 200)
        }
      }
    },
    {
      element: '#search-box',
      popover: {
        title: 'Pesquisar series de dados',
        description: `Neste campo iremos pesquisar as series de dados existentes, basta informar o nome da serie.`,
        onNextClick: () => {
          const inputSearchData: any = document.querySelector('#input-search-series-data');
          inputSearchData.value = 'dados';

          setTimeout(() => {
            driverObj.moveNext();
          }, 200)          
        }
      }
    },
    {
      element: '#btn-search-series-data',
      popover: {
        title: 'Buscar séries de dados',
        description: `Após informar o nome da serie, basta clicar neste botão para buscar as series de dados.`,
        onNextClick: () => {
          const btnSearchData: any = document.querySelector('#btn-search-series-data');
          triggerEvent(btnSearchData, 'click', true);

          setTimeout(() => {
            const reqAnalisesRequests = checkRequestComplete.getRequest({nameRequest: ['analisesDataRepoRequest']});
            const hasRequestNull = reqAnalisesRequests.filter(req => req === null);
  
            const thisStep = driverObj.getActiveIndex()
            let controlFlow = 9;

            if(hasRequestNull.length === 0 && reqAnalisesRequests[0] === 'success') {

              if(thisStep === 8) {
                controlFlow = 10;
              }
            }
            
            driverObj.moveTo(controlFlow);
          }, 500)
        }
      }
    },
    {
      element: '#alert-home',
      popover: {
        title: 'Atenção',
        description: 'Para uma melhor experiencia aguarde o carregamento completo da página',
        disableButtons: ['next'],
      }
    },
    {
      element: '.list-data-series',
      popover: {
        title: 'Minhas series de dados',
        description: `Após a pesquisar, aqui serão exibidas as series de dados encontradas. Vamos selecionar uma para analisa-la.`,
        onNextClick: () => {
          const listSeriesData: any = document.querySelectorAll('.list-data-series > div')
          
          let data_single: any = ''
          
          listSeriesData.forEach((item: any) => {
            if(item.children[1].children[0].children[1].textContent === 'CSV') {
              data_single = item;
              return;
            };
          });
          
          triggerEvent(data_single.children[1].children[0].children[0], 'click', true);   

          setTimeout(() => {
            driverObj.moveNext();
          }, 1000);
        },
        onPrevClick: () => {
          const thisStep: any = driverObj.getActiveIndex()

          let controlFlow = 7;
            
          if(thisStep === 9) {
            controlFlow = 6;
          }

          driverObj.moveTo(controlFlow);
        }
      }
    },
    {
      element: '#series-used',
      popover: {
        title: 'Minhas serie de dados',
        description: `Aqui vão ser exibidas as informações das series de dados. <br /> Caso deseje comparar duas series de dados, clique no botão 'Comparar'`,     
      }
    },
    {
      element: '#add-serie-compare',
      popover: {
        title: 'Comparar series de dados',
        description: `Clique aqui para adicione as series de dados que deseja comparar`,
        onNextClick: () => {
          setTimeout(() => {
            driverObj.moveNext();
          }, 200)
        }
      }
    },
    {
      element: '.nav-tabs .nav-item:nth-child(1)',
      popover: {
        title: 'Insights analisados',
        description: `Nesta aba vão ser exibidas as series de dados que foram analisadas.`,
        onNextClick: () => {
          const btnAbaInsightAnalityc: any = document.querySelector('.nav-tabs .nav-item:nth-child(1) a');
          triggerEvent(btnAbaInsightAnalityc, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    {
      element: '.graph_dispersion',
      popover: {
        title: 'Insights analisados',
        description: `Nesta aba vão ser exibidas as series de dados que foram analisadas.`,
        onNextClick: () => {
          const btnColumnGraphic: any = document.querySelector('#dropdown-statistics');
          triggerEvent(btnColumnGraphic, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    // {
    //   element: '#dropdown-statistics',
    //   popover: {
    //     title: 'Colunas do gráfico',
    //     description: `Ao clicar neste botão você pode escolher que coluna deseja mostrar no gráfico`,
    //   }
    // },
    // {
    //   element: '#options-statistics',
    //   popover: {
    //     title: 'Colunas do gráfico',
    //     description: `Selecione a coluna que deseja mostrar no gráfico. <hr />
    //                   Vamos selecionar a coluna 'Umidade' para mostrar no gráfico
    //     `,
    //   }
    // },
    {
      element: '.graph_dispersion',
      popover: {
        title: 'Gráfico da análise',
        description: `No botão a direita do gráfico vão ser exibidas novas colunas para análise.`,
        onNextClick: () => {
          const btnColumnGraphic: any = document.querySelector('#dropdown-statistics');
          triggerEvent(btnColumnGraphic, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 1000)
        }
      }
    },
    {
      element: '.graph_dispersion',
      popover: {
        title: 'Gráfico da análise',
        description: `Clique e arraste a parte inferior e a direita para aumentar as informações no gráfico`,
      }
    },
    {
      element: '.card-analyses:nth-child(1)',
      popover: {
        title: 'Quantidade total de dados',
        description: `.`,
      }
    },
    {
      element: '.card-analyses:nth-child(2)',
      popover: {
        title: 'Moda',
        description: `.`,
      }
    },
    {
      element: '.card-analyses:nth-child(3)',
      popover: {
        title: 'Media',
        description: `.`,
      }
    },
    {
      element: '.card-analyses:nth-child(4)',
      popover: {
        title: 'Mediana',
        description: `.`,
      }
    },
    {
      element: '.card-analyses:nth-child(5)',
      popover: {
        title: 'Desvio padrão 1',
        description: `.`,
      }
    },
    {
      element: '.card-analyses:nth-child(6)',
      popover: {
        title: 'Desvio padrão 2',
        description: `.`,
      }
    },
    {
      element: '.card-analyses:nth-child(7)',
      popover: {
        title: 'Desvio padrão 3',
        description: `.`,
      }
    },
    {
      element: '.card-analyses:nth-child(8)',
      popover: {
        title: 'Quartil 1',
        description: `.`,
      }
    },
    {
      element: '.card-analyses:nth-child(9)',
      popover: {
        title: 'Quartil 3',
        description: `.`,
      }
    },
    {
      element: '.card-analyses:nth-child(10)',
      popover: {
        title: 'Limite superior',
        description: `.`,
      }
    },
    {
      element: '.card-analyses:nth-child(11)',
      popover: {
        title: 'Limite inferior',
        description: `.`,
      }
    },
    { 
      element: '#normal-distribution', 
      popover: { 
        title: 'Grafico de distribuição de Probabilidade', 
        description: 'Aqui estará o gráfico de distribuição de probabilidade e informações releventes da analise.',
      } 
    },
    { 
      element: '#correlation-matriz', 
      popover: { 
        title: 'Matriz de correlação', 
        description: 'Neste gráfico vão ser exibidas as correlações entre as variáveis. É possível interagir arrastando a régua que fica na parte inferior.',
      } 
    },
    {
      element: '.nav-tabs .nav-item:nth-child(2)',
      popover: {
        title: 'Predições simples',
        description: `Vamos ver o que temos de predições. Basta clicar nesta aba para ver a predição.`,
        onNextClick: () => {
          const btnAbaInsightAnalityc: any = document.querySelector('.nav-tabs .nav-item:nth-child(2) a');
          triggerEvent(btnAbaInsightAnalityc, 'click', true);

          const btnColumnGraphic: any = document.querySelector('#dropdown-statistics');
          triggerEvent(btnColumnGraphic, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    {
      element: '#graph-prediction',
      popover: {
        title: 'Predições',
        description: `Veja o que mostra o gráfico de predição e intereja com ele selecionando outras colunas no botão a direita.`,
        onNextClick: () => {
          const btnColumnGraphic: any = document.querySelector('#dropdown-statistics');
          triggerEvent(btnColumnGraphic, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    {
      element: '#cards-analyses-emq-fu',
      popover: {
        title: 'Erro médio quadrado e Função utilizada',
        description: `Veja os resultados do erro médio quadrado e a função utilizada.`,
      }
    },
    {
      element: '#graph_correlation',
      popover: {
        title: 'Auto correlação',
        description: `Veja o que mostra o gráfico de autocorrelação e intereja com ele selecionando outras colunas no botão a direita.`,
        onNextClick: () => {
          const btnColumnGraphic: any = document.querySelector('#dropdown-statistics');
          triggerEvent(btnColumnGraphic, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    {
      element: '.nav-tabs .nav-item:nth-child(3)',
      popover: {
        title: 'Analises avançadas',
        description: `Vamos ver o que temos em analises avançadas. Basta clicar nesta aba para ver as analises.`,
        onNextClick: () => {
          const btnAbaInsightAnalityc: any = document.querySelector('.nav-tabs .nav-item:nth-child(3) a');
          triggerEvent(btnAbaInsightAnalityc, 'click', true);

          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    {
      element: '#graph_histogram_distribution',
      popover: {
        title: 'Histograma e Distribuição',
        description: `Aqui vemos o resultado do histograma e a distribuição e nele é possível interagir clicando no botão a direita, é possível selecionar apenas um dos graficos: linha ou barra.`,
      }
    },
    {
      element: '#graph_histogram',
      popover: {
        title: 'Histograma',
        description: `Aqui vemos o resultado do histograma e nele é possível interagir clicando no botão a direita.`,
      }
    },
    {
      element: '#graph_dispersion',
      popover: {
        title: 'Gráfico de Dispersão',
        description: `Aqui vemos o resultado no gráfico de dispersão e nele é possível interagir clicando no botão a direita e arrastando na regua na parte inferior do gráfico.`,
      }
    },
    { 
      element: '#menu-tutorial', 
      popover: { 
        title: 'Tutorial encerrado, Obrigado!', 
        description: 'Você pode ver novamente este tutorial clicando no menu Tutoriais',
      } 
    },
  ]

  const StepsDashboard = [
    {
      element: window.innerWidth <= 992 ? '.offcanvas #menu_dashboard' : '#menu_dashboard',
      popover: {
        title: 'Dashboard',
        describer: `Veja informações chaves da plataforma em no Dashboard.`,
        onNextClick: () => {
          const moduleDashboard: any = document.querySelector('#menu_dashboard > a');
          triggerEvent(moduleDashboard, 'click', true);
          setTimeout(() => {
            driverObj.moveNext();
          }, 500)
        }
      }
    },
    {
      element: '#dashboard_center',
      popover: {
        title: 'Dashboard',
        description: `Veja informações chaves da plataforma em no Dashboard.`,
        onNextClick: () => {
          const reqDashboard = checkRequestComplete.getRequest(
            {nameRequest: ['dashOkrRequest', 'dashSectorsRequest', 'dashKpisRequest']}
          );
          const hasRequestNull = reqDashboard.filter(req => req === null);

          if(hasRequestNull.length === 0) {
            const thisStep = driverObj.getActiveIndex()
            let controlFlow = 2;
            
            if(thisStep === 1) {
              controlFlow = 3;
            }
            
            driverObj.moveTo(controlFlow);
            return;
          }

          driverObj.moveNext();
        },
      }
    },
    {
      element: '#alert-home',
      popover: {
        title: 'Atenção',
        description: 'Para uma melhor experiencia aguarde o carregamento completo da página',
        disableButtons: ['next'],
      }
    },
    {
      element: '.area-wrapper',
      popover: {
        title: 'Minhas zonas',
        description: `Vejas as zonas que você possui em seu perfil.`,
        onPrevClick: () => {
          const thisStep = driverObj.getActiveIndex()
          let controlFlow = 1;

          if(thisStep === 2) {
            controlFlow = 0;
          }

          driverObj.moveTo(controlFlow)
        }
      }
    },
    {
      element: '.table-wrapper',
      popover: {
        title: 'Minhas Culturas',
        description: `Aqui será listadas as culturas que você possui em seu perfil com informações de: Cultura, Zona, Setor, Seção e Expectativa da produção.`,
      }
    },
    {
      element: '.kpis',
      popover: {
        title: 'Meu indicadores de performance',
        description: `Vejas alguns dos seus indicadores de performance e intereja com eles.`,
      }
    },
    {
      element: '.okrs',
      popover: {
        title: 'Minhas Metas',
        description: `Vejas as suas metas e intereja com elas.`,
      }
    },
    { 
      element: '#menu-tutorial', 
      popover: { 
        title: 'Tutorial encerrado, Obrigado!', 
        description: 'Você pode ver novamente este tutorial clicando no menu Tutoriais',
      } 
    },
  ]

  const AllStepsAglut: any = {
    "inicio": StepsHome,
    "teams": StepsTeam,
    "event": StepsOccurrences,
    "kpis": StepsKpi,
    "okrs": StepsOkrs,
    'sector': StepsMap,
    'dataMarker': StepsMarketData,
    'first-time': StepsFirstTime,
    'settings': StepsSettings,
    'dataAnalytic': StepsDataAnalytics,
    'dashboard': StepsDashboard
  }

  let nameModule = module;
  let dialogIsOpen = false;
  //TODO: Adiciona validação para verificar se o menu lateral está no mobile

  const driverObj = driver({
    prevBtnText: 'Voltar',
    nextBtnText: 'Avançar',
    doneBtnText: 'Concluir',
    allowClose: true,
    showProgress: true,
    allowKeyboardControl: true,
    onDestroyStarted: () => {
      if (!dialogIsOpen) {
        document.body.removeAttribute('class')

        const modal = document.createElement('div');
        const text = document.createElement('div');
        const confirm = document.createElement('div');
        const cancel = document.createElement('div');
        const wrapperButtons = document.createElement('div');
        confirm.setAttribute('id', 'confirm')
        cancel.setAttribute('id', 'cancel')
        modal.setAttribute('id', 'wrapper-modal');

        modal.style.width = '100%';
        modal.style.height = '100%';
        modal.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
        modal.style.position = 'absolute';
        modal.style.top = '0';
        modal.style.left = '0';
        modal.style.right = '0';
        modal.style.bottom = '0';

        modal.style.zIndex = '1000000000';
        modal.style.display = 'flex';
        modal.style.justifyContent = 'center';
        modal.style.cursor = 'pointer';
        modal.style.pointerEvents = 'auto';

        text.style.display = 'flex'
        text.style.fontSize = '16px'
        text.style.flexDirection = 'Column'
        text.style.alignItems = 'center'
        text.style.justifyContent = 'center'
        text.style.padding = "16px 42px"
        text.style.backgroundColor = '#FFF'
        text.style.position = "absolute"
        text.style.top = "32px"
        text.style.left = '50%'
        text.style.transform = 'translateX(-50%)';
        text.style.zIndex = '1000000002';
        text.style.borderRadius = '4px';
        text.style.boxShadow = '0px 0px 25px 4px rgba(0, 0, 0, 0.15)';
        text.innerText = "Deseja encerrar o tutorial?"

        wrapperButtons.style.display = 'flex'
        wrapperButtons.style.justifyContent = 'flex-end'
        wrapperButtons.style.marginTop = '16px'
        wrapperButtons.style.position = 'relative'
        wrapperButtons.style.zIndex = '1000002222';
        wrapperButtons.style.cursor = "pointer"

        confirm.innerText = "Sim"
        confirm.style.padding = "8px 32px"
        confirm.style.border = "none"
        confirm.style.borderRadius = "4px"
        confirm.style.backgroundColor = "#383838"
        confirm.style.color = "#FFF"
        confirm.style.cursor = "pointer"
        confirm.style.zIndex = '1000003222';

        cancel.innerText = "Não"
        cancel.style.padding = "8px 32px"
        cancel.style.border = "none"
        cancel.style.borderRadius = "4px"
        cancel.style.backgroundColor = "#FFF"
        cancel.style.color = "#383838"
        cancel.style.cursor = "pointer"
        cancel.style.marginLeft = "8px"
        cancel.style.zIndex = '1000003222';
        
        wrapperButtons.appendChild(confirm)
        wrapperButtons.appendChild(cancel)
        text.appendChild(wrapperButtons)

        document.body.appendChild(text);
        document.body.appendChild(modal);

        const btnConfirm: any = document.querySelector('#confirm') && document.querySelector('#confirm');
        btnConfirm.addEventListener('click', () => {
          document.body.removeChild(text);
          document.body.removeChild(modal);
          driverObj.destroy();

          handleSetFirstStep(nameModule)
        })
        
        const btnCancel: any = document.querySelector('#cancel') && document.querySelector('#cancel');
        btnCancel.addEventListener('click', () => {
          handleSetFirstStep(nameModule)
          document.body.removeChild(text);
          document.body.removeChild(modal);
          dialogIsOpen = false;
        })

        if (!driverObj.hasNextStep()) {
          document.body.removeChild(text);
          document.body.removeChild(modal);
          driverObj.destroy();
        }
      }

      dialogIsOpen = true;
    },
    overlayColor: 'rgba(0, 0, 0, 1)',
    progressText: '{{current}} de {{total}}',
    steps: isPathnameEqualToModule ? AllStepsAglut[nameModule].slice(1) || -1 : AllStepsAglut[nameModule] || -1,
  });

  return driverObj.drive();
}
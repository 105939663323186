import { Dropdown } from "react-bootstrap";
import { DropdownContent } from "./styled";
import { BiChart, BiMenu, BiRadar, BiShoppingBag, BiTachometer } from "react-icons/bi";
import Link from "next/link";

export default function DropdownServices() {
    return (
        <DropdownContent>
            <Dropdown className="dropdown-services">
                <Dropdown.Toggle id="dropdown-services">
                    <BiMenu size={22}/>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <div className="menu-services">
                        <a 
                            href="http://market.zaori.local" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            title="Acessar serviço Marketplace" 
                            aria-label="Acessar serviço Marketplace" 
                            className="dropdown-item item-service"
                        >
                            <BiShoppingBag size={32}/>
                            Marketplace
                        </a>
                        <a 
                            href="http://radartrends.zaori.local" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            title="Acessar serviço Radar Trends" 
                            aria-label="Acessar serviço Radar Trends" 
                            className="dropdown-item item-service"
                        >
                            <BiRadar size={32}/>
                            Radar Trends
                        </a>
                        <a 
                            href="http://datus.zaori.local" 
                            target="_blank" 
                            rel="noopener noreferrer" 
                            title="Acessar serviço Datus" 
                            aria-label="Acessar serviço Datus" 
                            className="dropdown-item item-service"
                        >
                            <BiTachometer size={32}/>
                            Datus
                        </a>
                        <Link 
                            href="/" 
                            title="Acessar serviço Aglut" 
                            aria-label="Acessar serviço Aglut" 
                            className="dropdown-item item-service active"
                        >
                            <BiChart size={32}/>
                            Aglut
                        </Link>
                    </div>
                </Dropdown.Menu>
            </Dropdown>
        </DropdownContent>
    )
}
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

type SliderTimeContextType = {
  goNextDays: (qtdDias: number) => void;
  goNextHours: (qtdHoras: number) => void;
  goBackHours: (qtdHoras: number) => void;
  goBackDays: (qtdDias: number) => void;
  goToToday: () => void;
  goToDateSelected: (date: string) => void;
  setLoadingDate: any;
  isLoadingDate: boolean;
  currentDate: string;
  currentHour: string;
  currentMouth: number;
};

const SliderTimeContext = createContext({} as SliderTimeContextType);
//TODO: Validar quando passar o mês, ele mudar o mês (verificar mes com dias 30,31,29 e 28)
//TODO: Validar quando passar o ano, ele voltar para 01/01 do proximo ano

const SliderTimeProvider = ({ children }: any) => {
  const [currentDate, setCurrentDate] = useState("");
  const [currentHour, setCurrentHour] = useState<string>("");
  const [currentMouth, setCurrentMouth] = useState(1);
  const [formatNewDate, setFormatNewDate] = useState(new Date());
  const [isLoadingDate, setLoadingDate] = useState(true);

  /**
   * Função para formatar os dias na data correta para o brasil.
   * @param {number} day  Dia
   * @param {number} month Mês
   * @param {number} year Ano
   * @returns {string} String da Data formatada
   */
  const formatDateBr = (day: number, month: number, year: number): string => {
    const formatDay = day < 10 ? `0${day}` : day;
    const formatMonth = month < 10 ? `0${month}` : month;

    return `${formatDay}/${formatMonth}/${year}`;
  };

  /**
   * Função para avançar no tempo
   * @param qtdDias Quantidade de Dias
   */
  const goNextDays = (qtdDias: number) => {
    const dayCurrentTime = formatNewDate.getTime();
    const umDiaEmMilissegundos = 24 * 60 * 60 * 1000;
    //cspell:disable-next-line
    const dataAvancada = new Date(
      dayCurrentTime + qtdDias * umDiaEmMilissegundos
    );
    setCurrentDate(
      formatDateBr(
        //cspell:disable-next-line
        dataAvancada.getDate(),dataAvancada.getMonth() + 1,dataAvancada.getFullYear()
      )
    );
    //cspell:disable-next-line
    if (currentMouth !== dataAvancada.getMonth() + 1) {
      //cspell:disable-next-line  
      setCurrentMouth(dataAvancada.getMonth() + 1);
    }
    //cspell:disable-next-line
    setFormatNewDate(dataAvancada);
  };

  /**
   * Função para voltar no tempo
   * @param qtdDias Quantidade de Dias
   */
  const goBackDays = (qtdDias: number) => {
    const dayCurrentTime = formatNewDate.getTime();
    const umDiaEmMilissegundos = 24 * 60 * 60 * 1000;
    //cspell:disable-next-line
    const dataAvancada = new Date(
      dayCurrentTime - qtdDias * umDiaEmMilissegundos
    );
    setCurrentDate(
      formatDateBr(
        //cspell:disable-next-line
        dataAvancada.getDate(),dataAvancada.getMonth() + 1,dataAvancada.getFullYear()
      )
    );
    //cspell:disable-next-line
    if (currentMouth !== dataAvancada.getMonth() + 1) {
      //cspell:disable-next-line
      setCurrentMouth(dataAvancada.getMonth() + 1);
    }
    //cspell:disable-next-line
    setFormatNewDate(dataAvancada);
  };

  
  /**
   * Updates the current hour by adding the specified number of hours.
   *
   * @param {number} qtdHoras - the number of hours to add
   * @return {void} 
   */
  const goNextHours = (qtdHoras: number) => {
    const hourCurrentTime = currentHour.split(':')[0];
    const newHour = Number(hourCurrentTime) + qtdHoras;
    if(newHour > 23) {
      goNextDays(1);
      setCurrentHour("00:00");
    } else {
      setCurrentHour((newHour < 10 ? "0" + newHour : newHour) + ":" + "00");
    }
  }

  /**
   * Calculates a new hour by subtracting the specified number of hours from the current hour.
   *
   * @param {number} qtdHoras - the quantity of hours to subtract
   * @return {void}
   */
  const goBackHours = (qtdHoras: number) => {
    const hourCurrentTime = currentHour.split(':')[0];
    const newHour = Number(hourCurrentTime) - qtdHoras;
    if(newHour < 0) {
      goBackDays(1);
      setCurrentHour("23:00");
    } else {
      setCurrentHour((newHour < 10 ? "0" + newHour : newHour) + ":" + "00");
    }
  }

  /**
   * Função para ir ao dia atual
   */
  const goToToday = () => {
    const today = new Date();
    setCurrentDate(
      formatDateBr(today.getDate(), today.getMonth() + 1, today.getFullYear())
    );
    if (currentMouth !== today.getMonth() + 1)
      setCurrentMouth(today.getMonth() + 1);
    setFormatNewDate(today);
  };

  /**
   * Function to go to the selected date.
   *
   * @param {string} date - the selected date in string format
   */
  const goToDateSelected = (date: string) => {
    const dateSelected = new Date(date);
    setCurrentDate(
      formatDateBr(
        dateSelected.getDate(),
        dateSelected.getMonth() + 1,
        dateSelected.getFullYear()
      )
    );
    if (currentMouth !== dateSelected.getMonth() + 1)
      setCurrentMouth(dateSelected.getMonth() + 1);
    setFormatNewDate(dateSelected);
  }

  useEffect(() => {
    const dateToday = new Date();
    setCurrentDate(
      formatDateBr(
        dateToday.getDate(),
        dateToday.getMonth() + 1,
        dateToday.getFullYear()
      )
    );
    setCurrentHour((dateToday.getHours() < 10 ? "0" + dateToday.getHours() : dateToday.getHours()) + ":" + "00");
    if (currentMouth !== dateToday.getMonth() + 1)
      setCurrentMouth(dateToday.getMonth() + 1);
    setFormatNewDate(dateToday);
    if (process.env.NEXT_PUBLIC_API_LAYERS_DISABLED) {
      setLoadingDate(false);
    }
  }, []);

  return (
    <SliderTimeContext.Provider
      value={{
        goNextDays,
        goBackDays,
        goNextHours,
        goBackHours,
        goToToday,
        goToDateSelected,
        currentDate,
        setLoadingDate,
        isLoadingDate,
        currentMouth,
        currentHour,
      }}
    >
      {children}
    </SliderTimeContext.Provider>
  );
};

function useSliderTimeProv(): SliderTimeContextType {
  const context = useContext(SliderTimeContext);

  if (!context) {
    throw new Error("useMain must be used within an MainProvider");
  }

  return context;
}

export { SliderTimeProvider, useSliderTimeProv };
